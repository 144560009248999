import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";

function getDeckGlLayers(layerData) {
  if (!layerData) return [];

  return [
    new GeoJsonLayer({
      id: "geojson",
      data: layerData,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: 1,
      visible: true,
      getFillColor: (f) => {
        return f?.properties?.fill
          ? hexToRgb(f?.properties?.fill)
          : getServiceColorRgb(f?.properties?.layerType);
      },
      getLineColor: (f) => {
        return f?.properties?.stroke
          ? hexToRgb(f?.properties?.stroke)
          : getServiceColorRgb(f?.properties?.layerType);
      },
      lineWidthMinPixels: 2,
    }),
  ];
}

const PlannerSelectedLayers = ({ layerData }) => {
  const [isVisible, setIsVisible] = useState(true);

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(layerData)}
      pickable={true}
      isVisible={isVisible}
    />
  );
};

export default PlannerSelectedLayers;
