import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextStep,
  reset,
  setLocationName,
  setLocationPinCoordinate,
  setParcelGeoJSON,
  setStepsLabels,
} from "../../../slices/stepperFormSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateOrderMutation,
  useLazyGetSpecificOrderQuery,
  useUpdateSpecificOrderMutation,
} from "../../../services/OpenApi";
import { toast } from "react-toastify";
import { resetDrawingSlice } from "../../../slices/drawingSlice";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";

const SiteDetailsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state) => state.global.user);
  const locationName = useSelector((state) => state.stepperForm.locationName);
  const locationPinCoordinate = useSelector(
    (state) => state.stepperForm.locationPinCoordinate,
  );
  const [createOrder, { isLoading }] = useCreateOrderMutation();
  const [getSpecificOrder] = useLazyGetSpecificOrderQuery();
  const [updateSpecificOrder] = useUpdateSpecificOrderMutation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    if (params?.id) {
      setFinalData(data);
      setIsConfirmationModalOpen(true);
    } else {
      try {
        const response = await createOrder({
          orgName: user?.organization,
          content: {
            emailId: user.email,
            companyName: user?.organization,
            locationCoordinates: {
              lat: locationPinCoordinate.lat,
              lon: locationPinCoordinate.lng,
            },
            ...data,
          },
        });
        if (response?.data) {
          navigate(`/parcel/${response?.data?.data.id}`);
          toast.success("Site details added", {
            autoClose: 1000,
            hideProgressBar: true,
          });
          dispatch(handleNextStep());
        } else if (response?.error) {
          toast.error(
            response?.error?.error || response?.error?.data?.message,
            {
              autoClose: 2000,
              hideProgressBar: true,
            },
          );
        }
      } catch (err) {
        console.log("error => ", err);
      }
    }
  };

  const handleConfirmationSubmit = async (isRemoveParcel) => {
    if (params?.id) {
      try {
        const response = await updateSpecificOrder({
          orgName: user?.organization,
          orderId: params?.id,
          content: {
            removeParcel: isRemoveParcel,
            emailId: user.email,
            companyName: user?.organization,
            locationCoordinates: {
              lat: locationPinCoordinate.lat,
              lon: locationPinCoordinate.lng,
            },
            ...finalData,
          },
        });
        if (response?.data) {
          navigate(`/parcel/${params?.id}`);
          toast.success("Site details added", {
            autoClose: 1000,
            hideProgressBar: true,
          });
          if (isRemoveParcel) {
            dispatch(setParcelGeoJSON(null));
            dispatch(resetDrawingSlice());
          }
          dispatch(handleNextStep());
        } else if (response?.error) {
          toast.error(
            response?.error?.error || response?.error?.data?.message,
            {
              autoClose: 2000,
              hideProgressBar: true,
            },
          );
        }
      } catch (err) {
        console.log("error => ", err);
      }
    }
    setIsConfirmationModalOpen(false);
  };

  const handleAddressChange = (e) => {
    dispatch(setLocationName(e.target.value));
    dispatch(setStepsLabels({ id: 1, description: e.target.value }));
  };

  const handleEditLocation = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (params?.id) {
      (async () => {
        try {
          const response = await getSpecificOrder({
            orderId: params?.id,
            orgName: user?.organization,
          });
          if (response?.data) {
            const { address, description, siteName, locationCoordinates } =
              response?.data?.data;
            if (!locationName) setLocationName(address);
            setValue("description", description);
            setValue("siteName", siteName);
            if (!locationPinCoordinate) {
              dispatch(
                setLocationPinCoordinate({
                  lat: locationCoordinates?.lat,
                  lng: locationCoordinates?.lon,
                }),
              );
            }
          }
        } catch (err) {
          console.log("error => ", err);
        }
      })();
    }
  }, []);

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Your Address</h3>
      <Typography
        sx={{ cursor: "pointer" }}
        onClick={handleEditLocation}
        component="span"
        color="primary.dark"
        variant="body2"
      >
        Edit Location
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleFormSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <TextField
          size="small"
          fullWidth
          required
          id="siteName"
          label="Site Name"
          name="siteName"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 52 }}
          {...register("siteName", {
            required: "Site name is required",
            maxLength: {
              value: 52,
              message: "Site name should not exceed 52 characters",
            },
            validate: (value) => {
              let processedValue = value.trim().replace(/ +/g, "_");
              const allowedCharactersRegex = /^[a-zA-Z0-9_.\-\/]*$/;
              if (!allowedCharactersRegex.test(processedValue)) {
                return "Invalid characters detected. Allowed characters are: letters, numbers, '_', '-', '.', and '/'.";
              }
              return true; 
            },
          })}
          error={!!errors.siteName}
          helperText={errors.siteName ? errors.siteName.message : ""}
        />
        <TextField
          size="small"
          fullWidth
          required
          id="address"
          label="Address"
          name="address"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          {...register("address", {
            required: "Address is required",
            onChange: handleAddressChange,
          })}
          value={locationName}
          error={!!errors.address}
          helperText={errors.address ? errors.address.message : ""}
        />
        <TextField
          size="small"
          fullWidth
          id="description"
          label="Description"
          name="description"
          InputLabelProps={{ shrink: true }}
          {...register("description")}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          disabled={isLoading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
      </Box>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete parcel data?"
        secondaryButtonTitle="No"
        PrimaryButtonTitle="Yes"
        secondaryButtonVariant="outlined"
        primaryButtonVariant="outlined"
        secondaryButtonColor="error"
        secondaryButtonClick={() => handleConfirmationSubmit(false)}
        handleOk={() => handleConfirmationSubmit(true)}
      />
    </div>
  );
};

export default SiteDetailsForm;
