import { Box, Drawer } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useViewportWidth from "../../Hooks/useViewportWidth";
import "../GISDashboard/GISDashboardSidebar.css";
import { PlanningDashboardSidebarSummary } from "./PlanningDashboardSidebarSummary";
import { setPlanningInputData } from "../../slices/planningDashboardSlice";
import PlanningDashboardPlannedData from "./Components/PlanningDashboardPlannedData";

const DashboardSidebar = ({
  isPlannerDrawerOpen,
  runPlannerData,
  isRunPlannerDataLoading,
 }) => {

  const dispatch = useDispatch();
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );

  const planningStatus = useSelector(
    (state) => state.planningDashboard.planningStatus,
  );

  const initialCrewData = useSelector(
    (state) => state.planningDashboard.plannedInitialCrewMembers,
  );
  useEffect(() => {
    if (runPlannerData) {
      dispatch(setPlanningInputData(runPlannerData?.data));
    }
  }, [runPlannerData, dispatch]);
  const width = useViewportWidth();

  return (
    <Drawer
      anchor="left"
      open={isPlannerDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: width < 768 ? "100vw" : "35vw",
          minWidth: "32rem",
          top: "calc(8vh + 3.2rem)",
          zIndex: 1,
        },
      }}
    >
      {((planningStatus==="SOLVING" || planningStatus==="SOLVING_SCHEDULED") && lookUp) ? (
        <Box className="sidebarDrawer">
          {initialCrewData && <PlanningDashboardPlannedData />}
        </Box>
      ) : (
        <PlanningDashboardSidebarSummary
          runPlannerData={runPlannerData}
          isLoading={isRunPlannerDataLoading}
        />
      )}
    </Drawer>
  );
};

export default DashboardSidebar;
