import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { AiOutlinePlus } from "react-icons/ai";

const BannerTop = () => {
  return (
    <div className="plannerBanner">
      <Typography variant="h4" component="h1" fontWeight={500}>
        Welcome to Planner
      </Typography>
      <Link
        to="/survey?from=true"
        target="_blank"
        referrerPolicy="no-referrer"
        style={{ textDecoration: "none" }}
      >
        <SecondaryButton
          sx={{
            borderRadius: "0.5rem",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <AiOutlinePlus />
          Add new property
        </SecondaryButton>
      </Link>
    </div>
  );
};

export default BannerTop;
