import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { hexToRgb } from "../../../utils/common";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { useSelector } from "react-redux";

function getDeckGlLayers(data, lookUpData) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "timelineHoveredGeoJsonLayer",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 1,
      opacity: 1,
      getFillColor: (f) => {
        const result = Object.keys(lookUpData?.polygons || {})
          .filter((polygonId) => {
            return polygonId.split('-').slice(1).join('-') == f?.properties?.pathId;
          })
          .map((polygonId) => lookUpData?.polygons[polygonId]); // Map the keys to their corresponding values

        const hexColor = lookUpData?.services[result[0]?.serviceId]?.color;
        return hexColor ? hexToRgb(hexColor) : hexToRgb(f?.properties?.fill);
      },
      getLineColor: (f) => {
        const result = Object.keys(lookUpData?.polygons || {})
          .filter((polygonId) => {
            return polygonId.split('-').slice(1).join('-') == f?.properties?.pathId;
          })
          .map((polygonId) => lookUpData?.polygons[polygonId]); // Map the keys to their corresponding values

        const hexColor = lookUpData?.services[result[0]?.serviceId]?.color;
        return hexColor ? hexToRgb(hexColor) : hexToRgb(f?.properties?.stroke);
      },
      pickable: false,
    }),
  ];
}
const TimelineHoveredPolygonLayer = ({ hoveredGeoJson }) => {
  const lookUpData = useSelector((state) => state.planningDashboard.plannedLookUpData);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(hoveredGeoJson, lookUpData)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default TimelineHoveredPolygonLayer;
