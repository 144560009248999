import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedPolygonParent: null,
  planningInputData: null,
  planningStatus: null,
  planningScore: null,
  plannedLookUpData: null,
  plannedInitialCrewMembers: null,
  planningMapGeoJsonData: null,
  planningMapGeoJsonFilteredData: null,
  planningPopupSelectedFilteredGeoJson: null,
  planningSelectedCrewMembersPathId: null,
  planningSelectedCrewMembersId:null,
  planningCrewMemberDayWiseFilteredData:null,
  selectedServices: null,
  showLabels: false,
  serviceWise: false,
  showNumberCircles: false,
  showArrowMarkers: false,
  showConnectingLines: false,
  // to sync hovered Polygon in timeline & highlighted polygon on map (one way)
  hoveredPolygon: null, 
};

const planningDashboardSlice = createSlice({
  name: "planningDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    setClickedPolygonParent: (state, action) => {
      return {
        ...state,
        clickedPolygonParent: action.payload,
      };
    },
    setPlanningInputData: (state, action) => {
      return {
        ...state,
        planningInputData: action.payload,
      };
    },
    setPlanningStatus: (state, action) => {
      return {
        ...state,
        planningStatus: action.payload,
      };
    },
    setPlanningScore: (state, action) => {
      return {
        ...state,
        planningScore: action.payload,
      };
    },
    setPlannedLookUpData: (state, action) => {
      return {
        ...state,
        plannedLookUpData: action.payload,
      };
    },
    setPlannedInitialCrewMembers: (state, action) => {
      return {
        ...state,
        plannedInitialCrewMembers: action.payload,
      };
    },
    setPlanningMapGeoJsonData: (state, action) => {
      return {
        ...state,
        planningMapGeoJsonData: action.payload,
      };
    },
    setPlanningMapGeoJsonFilteredData: (state, action) => {
      return {
        ...state,
        planningMapGeoJsonFilteredData: action.payload,
      };
    },
    setPlanningPopupSelectedFilteredGeoJson: (state, action) => {
      return {
        ...state,
        planningPopupSelectedFilteredGeoJson: action.payload,
      };
    },
    setPlanningSelectedCrewMembersPathId: (state, action) => {
      return {
        ...state,
        planningSelectedCrewMembersPathId: action.payload,
      };
    },
    setPlanningSelectedCrewMembersId: (state, action) => {
      return {
        ...state,
        planningSelectedCrewMembersId: action.payload,
      };
    },
    setPlanningCrewMemberDayWiseFilteredData: (state, action) => {
      return {
        ...state,
        planningCrewMemberDayWiseFilteredData: action.payload,
      };
    },
    setSelectedServices: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedServices[serviceType] =
          !state.selectedServices[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedServices = action.payload;
      }
    },
    setShowLabels: (state, action) => {
      return {
        ...state,
        showLabels: action.payload,
      };
    },
    setServiceWise: (state, action) => {
      return {
        ...state,
        serviceWise: action.payload,
      };
    },
    setShowNumberCircles: (state, action) => {
      return {
        ...state,
        showNumberCircles: action.payload,
      };
    },
    setShowArrowMarkers: (state, action) => {
      return {
        ...state,
        showArrowMarkers: action.payload,
      };
    },
    setShowConnectingLines: (state, action) => {
      return {
        ...state,
        showConnectingLines: action.payload,
      };
    },
    setHoveredPolygon: (state, action) => {
      return {
        ...state,
        hoveredPolygon: action.payload,
      };
    },
  },
});

export const {
  reset,
  setClickedPolygonParent,
  setPlanningInputData,
  setPlanningStatus,
  setPlanningScore,
  setPlannedLookUpData,
  setPlannedInitialCrewMembers,
  setPlanningMapGeoJsonData,
  setPlanningMapGeoJsonFilteredData,
  setPlanningPopupSelectedFilteredGeoJson,
  setPlanningSelectedCrewMembersPathId,
  setPlanningSelectedCrewMembersId,
  setPlanningCrewMemberDayWiseFilteredData,
  setSelectedServices,
  setShowLabels,
  setServiceWise,
  setShowNumberCircles,
  setShowArrowMarkers,
  setShowConnectingLines,
  setHoveredPolygon
} = planningDashboardSlice.actions;

export default planningDashboardSlice.reducer;
