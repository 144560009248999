import React from 'react'
import Pin from '../../../components/Mapbox/Pin';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

const PlanningParkingsMarkers = ({lookUpData}) => {
  return (
    <div>
         {/* for renderning parking markers */}
         {lookUpData &&
          lookUpData?.parkings &&
          Object.keys(lookUpData.parkings).map((parkingId, index) => {
            const parking = lookUpData.parkings[parkingId];
            return (
              <div style={{ zIndex: 2 }} key={parkingId}>
                <AdvancedMarker
                position={{lat: parking.point.lat, lng: parking.point.lon}}
                  zIndex={2}
                >
                  <Pin text="P" color="#ffa600"></Pin>
                </AdvancedMarker>
              </div>
            );
          })}
    </div>
  )
}

export default PlanningParkingsMarkers