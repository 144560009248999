import { Typography } from "@mui/material";
import React from "react";

const StatusBadge = ({ status }) => {
  const getStatusLabel = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "Submitted";
      case "PLANNED":
        return "Planned";
      case "READY_TO_PLANNED":
        return "Ready for Plan";
      case "PREPARING_PLAN":
        return "Preparing Plan";
      default:
        return "Draft";
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "#000E53";
      case "PLANNED":
        return "#2F3733";
      case "READY_TO_PLANNED":
        return "#16633A";
      case "PREPARING_PLAN":
        return "#4EA403";
      default:
        return "#7B650A";
    }
  };
  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "#D5DCFF";
      case "PLANNED":
        return "#FAFAFA";
      case "READY_TO_PLANNED":
        return "#D3FCE6";
      case "PREPARING_PLAN":
        return "#DBF4FF";
      default:
        return "#FFFBE6";
    }
  };

  return (
    <Typography
      variant="caption"
      component="span"
      style={{
        padding: "0rem 0.5rem",
        borderRadius: "1rem",
        color: getStatusColor(status),
        backgroundColor: getStatusBackgroundColor(status),
      }}
    >
      {getStatusLabel(status)}
    </Typography>
  );
};

export default StatusBadge;
