import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Menu,
  MenuItem, 
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";
import { MdExpandMore, MdExpandLess, MdUpload } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import {
  useDeleteOrderMutation,
  useGetOrderQuery,
  useLazyGetPlansQuery,
} from "../../../services/OpenApi";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { MdAdd, MdDelete, MdEdit, MdMoreVert } from "react-icons/md";
import { RotateLoader } from "react-spinners";
import { getCustomColor } from "../../../utils/theme";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import PlansModal from "../../../Modals/PlansModal/PlansModal";
import { formatReadableDate } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import PlansCard from "../../../Modals/PlansModal/PlansCard";
import UploadModal from "../../../Modals/UploadModal/uploadModal";
import { useCreateRunPlannerMutation } from "../../../services/OpenApi";
import { useSelector } from "react-redux";
import AddRunPlannerFileNameModal from "../../../Modals/AddRunPlannerFileNameModal/AddRunPlannerFileNameModal";
const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
  whiteSpace: "nowrap",
}));

const ParcelTable = (id) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const blackShade1 = getCustomColor("blackShade1");
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [deleteOrder] = useDeleteOrderMutation();
  const [plansDictionary, setPlansDictionary] = useState({});
  const [loadingPlans, setLoadingPlans] = useState({});
  const [anchorEls, setAnchorEls] = useState({});
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadData, setUploadData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const user1 = useSelector((state) => state.global.user);
  const handleOpenFileModal = (data) => {
    setIsFileModalOpen(true);
    setFileData(data);
  };

  const handleCloseFileModal = () => {
    setIsFileModalOpen(false);
    setFileData(null);
  };
  const [createRunPlanner] = useCreateRunPlannerMutation();
  const { data: orderData, isLoading } = useGetOrderQuery({
    orgName: user?.organization,
  });

  const [getPlans] = useLazyGetPlansQuery();
  const [selectedSite, setSelectedSite] = useState(null);
  const [setUploadModalOpen] = useState(false);

  const handleUploadClick = (site) => {
    setSelectedSite(site);
    setUploadModalOpen(true);
  };
  const handleToggleExpand = async (row) => {
    const id = row?.id
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    if(row.status==="READY_TO_PLANNED") {
      setPlansDictionary((prev) => ({
        ...prev,
        [id]: row?.v2RunPlannerDtoList,
      }));
    }  
    else if (!expandedRows[id] && id) {
      setLoadingPlans((prev) => ({ ...prev, [id]: true }));
      try {
        const response = await getPlans({
          orderId: id,
          orgName: user?.organization,
        });
        if (response?.data?.data) {
          setPlansDictionary((prev) => ({
            ...prev,
            [id]: response?.data?.data,
          }));
          setLoadingPlans((prev) => ({ ...prev, [id]: false }));
        }
      } catch {
        setLoadingPlans((prev) => ({ ...prev, [id]: false }));
      }
    } else {
      setLoadingPlans((prev) => ({ ...prev, [id]: false }));
    }
  };
  const handleAddressClick = (id) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/parcel/${id}`;
    window.open(newUrl, "_blank");
  };

  const handleClose = (rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: null }));
  };

  const onPropertyDelete = (id) => {
    setIsConfirmationModalOpen(true);
    setDeleteId(id);
    handleClose(id);
  };

  const onPropertyUpload = (data) => {
    setIsUploadModalOpen(true);
    // setDeleteId(id);
    // handleClose(id);
    setIsUploadModalOpen(true);
    setUploadData(data);
  };
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [SiteName, setSiteName] = useState("");

  const handleOpenUploadModal = (siteName) => {
    setSiteName(siteName);
    setOpenUploadModal(true);
  };
  const handleOk = async () => {
    try {
      const response = await deleteOrder({
        orderId: deleteId,
        orgName: user?.organization,
      });
      setIsConfirmationModalOpen(false);
    } catch (err) {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleViewClick = (row) => {
    if (row?.status === "READY_TO_PLANNED" || row?.status === "PREPARING_PLAN"|| row?.status === "SUBMITTED") {
      navigate(`summary/${row.id}`);
    } else {
      setIsModalOpen(true);
    }
  };

  const planStatus = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "Submitted";
      case "PLANNED":
        return "Planned";
      case "READY_TO_PLANNED":
        return "Ready for Plan";
      case "PREPARING_PLAN":
        return "Preparing Plan ";
      default:
        return "In Process";
    }
  };
  const fixedRowHeight = 56;
  return orderData?.data?.length > 0 ? (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          component="h3"
          fontWeight="600"
          sx={{ color: blackShade1.main }}
          variant="h5"
        >
          Measured Properties
        </Typography>
        <SecondaryButton
          type="button"
          marginTop="15px"
          marginBottom="10px"
          align="right"
          onClick={() => window.open("/survey", "_blank")}
          sx={{ width: "100px" }}
          variant="contained"
          background="#E0FFC4"
          textcolor="#3F8203"
        >
          <MdAdd /> Add new property
        </SecondaryButton>
      </Box>
      <div className="parcelTableContainer">
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead sx={{ backgroundColor: "#8FE445" }}>
              <TableRow style={{ height: `${fixedRowHeight}px` }}>
                <StyledTableCell
                  sx={{
                    maxWidth: 10,
                    backgroundColor: "#8FE445",
                    fontSize: "16px",
                  }}
                ></StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 80,
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Site Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  Address
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 130,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  Created By
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 120,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  Created At
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 50,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {orderData?.data?.map((row) => (
  <React.Fragment key={row.id}>
    <TableRow style={{ height: `${fixedRowHeight}px` }}>
      <StyledTableCell sx={{ maxWidth: 20 }}>
      </StyledTableCell>

      <StyledTableCell
        sx={{
          maxWidth: 150,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip title={row.site}>
          <span
            style={{
              fontWeight: 600,
              fontSize: "13px",
              color: "rgb(95, 97, 99)",
            }}
          >
            {row.site}
          </span>
        </Tooltip>
      </StyledTableCell>

      <StyledTableCell
        sx={{
          maxWidth: 170,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        align="center"
      >
        <Tooltip title={row.address}>
          <span
            style={{
              fontWeight: 600,
              fontSize: "13px",
              color: "rgb(95, 97, 99)",
            }}
          >
            {row.address}
          </span>
        </Tooltip>
      </StyledTableCell>

      <StyledTableCell align="center">
        <Tooltip title={row?.createdBy}>
          <span
            style={{
              fontWeight: 600,
              fontSize: "13px",
              color: "rgb(95, 97, 99)",
            }}
          >
            {row?.createdBy}
          </span>
        </Tooltip>
      </StyledTableCell>

      <StyledTableCell align="center">
        <Tooltip title={formatReadableDate(row?.createdAt)}>
          <span
            style={{
              fontWeight: 600,
              fontSize: "13px",
              color: "rgb(95, 97, 99)",
            }}
          >
            {formatReadableDate(row?.createdAt)}
          </span>
        </Tooltip>
      </StyledTableCell>

      <StyledTableCell
        align="center"
        sx={{
          color: row?.status === "SUBMITTED" ? "green" : "rgb(95, 97, 99)",
          fontWeight: 600,
          fontSize: "13px",
        }}
      >
        {planStatus(row?.status)}
      </StyledTableCell>

      <StyledTableCell align="right">
        <Box>
          {row?.status === "READY_TO_PLANNED" ? (
            <div className="tableActions">
              {row?.v2RunPlannerDtoList.length > 0 && (
                <IconButton
                  onClick={() => handleToggleExpand(row)}
                  style={{ marginRight: "-10px" }}
                >
                  {expandedRows[row.id] ? (
                    <MdExpandLess />
                  ) : (
                    <MdExpandMore />
                  )}
                </IconButton>
              )}
              <Tooltip title="View">
                <IoMdEye
                  onClick={() => {
                    handleViewClick(row);
                    handleClose(row.id);
                  }}
                  cursor="pointer"
                  fontSize={18}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <MdDelete
                  cursor="pointer"
                  onClick={() => onPropertyDelete(row.id)}
                  fontSize={18}
                />
              </Tooltip>
              <Tooltip title="Add Plan">
                <MdAdd
                  cursor="pointer"
                  onClick={() => handleOpenFileModal(row)}
                  fontSize={18}
                />
              </Tooltip>
            </div>
          ) : row?.status === "SUBMITTED" ? (
            <div className="tableActions">
              <Tooltip title="View">
                <IoMdEye
                  onClick={() => {
                    handleViewClick(row);
                    handleClose(row.id);
                  }}
                  cursor="pointer"
                  fontSize={18}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <MdDelete
                  cursor="pointer"
                  onClick={() => onPropertyDelete(row.id)}
                  fontSize={18}
                />
              </Tooltip>
              <Tooltip title="Upload">
                <MdUpload
                  cursor="pointer"
                  onClick={() => onPropertyUpload(row)}
                  fontSize={18}
                />
              </Tooltip>
            </div>
          ) : row?.status === "PREPARING_PLAN" ? (
            <div className="tableActions">
              <CircularProgress size={18} thickness={7} />
              <Tooltip title="View">
                <IoMdEye
                  onClick={() => {
                    handleViewClick(row);
                    handleClose(row.id);
                  }}
                  cursor="pointer"
                  fontSize={18}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <MdDelete
                  cursor="pointer"
                  onClick={() => onPropertyDelete(row.id)}
                  fontSize={18}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="tableActions">
              {row?.status === "PLANNED" && (
                <IconButton
                  onClick={() => handleToggleExpand(row)}
                  style={{ marginRight: "-10px" }}
                >
                  {expandedRows[row.id] ? (
                    <MdExpandLess />
                  ) : (
                    <MdExpandMore />
                  )}
                </IconButton>
              )}
              {!(row?.status === "PLANNED") && (
                <Tooltip title="Edit">
                  <MdEdit
                    onClick={() => {
                      handleAddressClick(row.id);
                      handleClose(row.id);
                    }}
                    cursor="pointer"
                    fontSize={18}
                  />
                </Tooltip>
              )}
              <Tooltip title="Delete">
                <MdDelete
                  cursor="pointer"
                  onClick={() => onPropertyDelete(row.id)}
                  fontSize={18}
                />
              </Tooltip>
            </div>
          )}
        </Box>
      </StyledTableCell>
    </TableRow>

    {expandedRows[row.id] && row?.status === "READY_TO_PLANNED" && (
      <TableRow>
        <TableCell colSpan={6}>
          <Box padding={2}>
            <div className="confirmationModalBody">
              <Box
                className="folderListContainer"
                marginTop={2}
                sx={{ height: "fit-content", width: "117%" }}
              >
                {
                  plansDictionary[row.id].length > 0 ? (
                    <Grid container spacing={2}>
                      {plansDictionary[row.id].map((folder) => (
                        <PlansCard
                          folder={folder}
                          parcelId={row?.id}
                          isNewParcel={row?.isNewParcel}
                          key={folder?.v2PlannerId}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <Typography>No plans available.</Typography>
                  )
                }
              </Box>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    )}

    {expandedRows[row.id] && row?.status === "PLANNED" && (
      <TableRow>
        <TableCell colSpan={6}>
          <Box padding={2}>
            <div className="confirmationModalBody">
              <Box
                className="folderListContainer"
                marginTop={2}
                sx={{ height: "fit-content", width: "117%" }}
              >
                {loadingPlans[row.id] ? (
                  <Grid container spacing={2}>
                    {[...Array(2)].map((_, index) => (
                      <Grid item xs={12} key={index}>
                        <Skeleton
                          variant="rectangular"
                          height={60}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : plansDictionary[row.id] ? (
                  plansDictionary[row.id].length > 0 ? (
                    <Grid container spacing={2}>
                      {plansDictionary[row.id].map((folder) => (
                        <PlansCard
                          folder={folder}
                          parcelId={row.id}
                          key={folder?.runPlannerId}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <Typography>No plans available.</Typography>
                  )
                ) : (
                  <Typography>No plans available.</Typography>
                )}
              </Box>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    )}
  </React.Fragment>
))}

            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          body="Do you want to delete this property?"
          handleOk={handleOk}
        />

        <UploadModal
          isOpen={isUploadModalOpen}
          setIsOpen={setIsUploadModalOpen}
          body="Do you want to Upload the file ?"
          handleOk={handleOk}
          uploadData={uploadData}
          site={selectedSite}
          onClose={() => setUploadModalOpen(false)}
        />
      </div>
      {/* Modal for adding file */}
      <AddRunPlannerFileNameModal isOpen={isFileModalOpen}
        onClose={handleCloseFileModal} fileData={fileData}/>
    </>
  ) : (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        display: isLoading ? "none" : "flex",
      }}
      className="emptyProperties"
    >
      <img src="/images/emptyProperty.png" width="350px" height="340px" />
      <SecondaryButton
        type="button"
        align="right"
        onClick={() => window.open("/survey", "_blank")}
        sx={{
          width: "100px",
          marginBottom: "10px",
        }}
        variant="contained"
        background="#E0FFC4"
        textcolor="#3F8203"
      >
        <MdAdd /> Add new property
      </SecondaryButton>
    </Box>
  );
};

export default ParcelTable;
