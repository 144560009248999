import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  TextField,
  Skeleton,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { getCustomColor } from "../../utils/theme";
import { FaMinusCircle } from "react-icons/fa";
import {
  useGetServicesQuery,
  useGetCompleteBeforeOptionsQuery,
  useGetLayersQuery,
} from "../../services/OpenApi";
import { MdClose } from "react-icons/md";
import ColorPickerButton from "../../components/Buttons/ColorPickerButton";

const AddServiceStep = ({
  readOnly = false,
  activeStep = 0,
  handleNext = () => {},
  handleLayerSelection = () => {},
  selectedServiceId,
  savedLayers,
  selectedCompleteBefore,
  selectedIsAddNewService,
  selectedNewServiceName,
  editServiceData,
  color,
  setColor,
}) => {
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const v2PlannerId = searchParams.get("v2PlannerId");
  const plannerId = searchParams.get("id");
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedLayers, setSelectedLayers] = useState([]);
  const [isAddNewService, setIsAddNewService] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");
  const [servicesDropdownData, setServicesDropdownData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  // Use RTK Query hooks
  const { data: servicesData, isLoading: loadingServices } =
    useGetServicesQuery({ id: plannerId, orgName: user?.organization });
  const { data: completeBeforeData, isLoading: loadingCompleteBefore } =
    useGetCompleteBeforeOptionsQuery({
      id: plannerId,
      orgName: user?.organization,
    });
  const { data: layersData, isLoading: loadingLayers } = useGetLayersQuery({
    id: v2PlannerId,
    orgName: user?.organization,
  });
  const completeBeforeOptions = completeBeforeData?.data || [];

  const onCheckboxChange = (layer) => {
    const updatedLayers = selectedLayers.includes(layer)
      ? selectedLayers.filter((l) => l !== layer)
      : [...selectedLayers, layer];

    setSelectedLayers(updatedLayers);
    handleLayerSelection(updatedLayers);
  };

  const [hasSubmitOnce, setHasSubmitOnce] = useState(false);
  const onSubmit = async (data) => {
    setHasSubmitOnce(true);
    if (
      selectedLayers.length === 0 ||
      (isAddNewService && !newServiceName.trim())
    )
      return;
    handleNext({
      coreServiceId: data.service, // Pass selected service ID as coreServiceId
      completeBefore: data.completeBefore,
      selectedLayers: selectedLayers,
      isAddNewService: isAddNewService,
      newServiceName: newServiceName,
      from: "service",
    });
  };

  useEffect(() => {
    if (servicesData?.data) {
      setServicesDropdownData(servicesData?.data);
    }
  }, [servicesData]);

  useEffect(() => {
    setValue("service", selectedServiceId);
    setValue("completeBefore", selectedCompleteBefore);
    setSelectedLayers(savedLayers);
    setIsAddNewService(selectedIsAddNewService);
    setNewServiceName(selectedNewServiceName ?? "");

    if (editServiceData) {
      const exists = servicesDropdownData.some(
        (service) => service.id === editServiceData.coreServiceId,
      );

      if (!exists) {
        setServicesDropdownData((prev) => [
          ...prev,
          {
            id: editServiceData.coreServiceId,
            serviceName: editServiceData.coreServiceName,
          },
        ]);
      }
    }
  }, [selectedServiceId, servicesDropdownData, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: 1, position: "relative" }}
    >
      {/* Service selection */}
      {!isAddNewService && (
        <Controller
          name="service"
          control={control}
          rules={{ required: "Service is required" }}
          render={({ field }) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "5px 0 -10px 0",
                }}
              >
                <Typography
                  fontWeight="600"
                  sx={{ color: blackShade1.light }}
                  variant="body1"
                >
                  Select a Service
                </Typography>
                {!readOnly && (
                  <Typography
                    fontWeight="500"
                    sx={{ color: greenShade1.shade1, cursor: "pointer" }}
                    variant="body1"
                    onClick={() => setIsAddNewService(true)}
                  >
                    Add new service
                  </Typography>
                )}
              </Box>
              <Stack
                spacing={{ xs: 1 }}
                direction="row"
                useFlexGap
                alignItems="flex-start"
                margin="10px 0 10px 0"
              >
                <FormControl fullWidth error={Boolean(errors.service)}>
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger("service");
                    }}
                    value={field.value || ""}
                    size="small"
                    error={!!errors.service}
                    disabled={loadingServices || readOnly} // Disable while loading
                    MenuProps={{
                      sx: {
                        maxHeight: "20rem",
                        maxWidth: "26rem",
                      },
                      PopoverClasses: {
                        paper: "modern-scroll",
                      },
                    }}
                  >
                    {servicesDropdownData.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.serviceName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.service && (
                    <FormHelperText>{errors.service.message}</FormHelperText>
                  )}
                </FormControl>
                <ColorPickerButton
                  value={color}
                  setValue={setColor}
                  disabled={readOnly}
                />
              </Stack>
            </>
          )}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
      {isAddNewService && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "5px 0 -10px 0",
            }}
          >
            <Typography
              fontWeight="600"
              sx={{ color: blackShade1.light }}
              variant="body1"
            >
              Add New Service
            </Typography>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setIsAddNewService(false)}
            >
              <FaMinusCircle color="red" />
            </Box>
          </Box>
          <Stack
            spacing={{ xs: 1 }}
            direction="row"
            useFlexGap
            alignItems="flex-start"
            margin="10px 0 10px 0"
          >
            <TextField
              size="small"
              fullWidth
              id="serviceName"
              name="serviceName"
              value={newServiceName}
              onChange={(e) => setNewServiceName(e.target.value)}
              error={hasSubmitOnce && !newServiceName.trim()}
              helperText={
                hasSubmitOnce && !newServiceName.trim()
                  ? "Service name cannot be empty"
                  : ""
              }
            />
            <ColorPickerButton value={color} setValue={setColor} />
          </Stack>
        </>
      )}

      {/* Complete Before selection */}
      <Controller
        name="completeBefore"
        control={control}
        render={({ field }) => (
          <>
            <Typography
              fontWeight="600"
              sx={{ mb: -2, color: blackShade1.light }}
              variant="body1"
            >
              Complete Before
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
              error={Boolean(errors.completeBefore)}
            >
              <Select
                {...field}
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e);
                }}
                size="small"
                disabled={loadingCompleteBefore || readOnly} // Disable while loading
                endAdornment={
                  field.value && (
                    <InputAdornment position="end" sx={{ marginRight: "10px" }}>
                      <IconButton
                        onClick={() => {
                          setValue("completeBefore", "");
                        }}
                        size="small"
                      >
                        <MdClose />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {completeBeforeOptions.length === 0 ? (
                  <MenuItem disabled>No options available</MenuItem>
                ) : (
                  completeBeforeOptions.map((option, index) => (
                    <MenuItem
                      key={`${option?.v2ServiceId} - ${index}`}
                      value={option.v2ServiceId}
                    >
                      {option?.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </>
        )}
      />

      {/* Layers selection */}
      <Typography
        fontWeight="600"
        sx={{ color: blackShade1.light }}
        variant="body1"
      >
        Select Layers
      </Typography>
      <Box className="layersContainer">
        {loadingLayers
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton
                key={`skeleton - ${index}`}
                height={30}
                sx={{ m: 0.5 }}
                variant="rectangular"
              />
            ))
          : layersData?.data?.map((layer, index) => (
              <Box key={`${layer?.coreLayerName} - ${index}`}>
                <Box
                  className="layerContainer"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 10px 0 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={selectedLayers.includes(layer.coreLayerName)}
                      onChange={() => onCheckboxChange(layer.coreLayerName)}
                      disabled={readOnly}
                    />
                    <Typography
                      fontWeight="500"
                      sx={{ color: blackShade1.light }}
                      variant="body1"
                    >
                      {layer.coreLayerName}
                    </Typography>
                  </Box>
                  <Box>
                    <span
                      style={{
                        backgroundColor: layer.color,
                        height: "0.8rem",
                        width: "0.8rem",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                  </Box>
                </Box>
                {index < layersData?.data?.length - 1 && <Divider />}
              </Box>
            ))}
      </Box>
      {hasSubmitOnce && selectedLayers.length === 0 && (
        <Typography
          color="error"
          variant="body2"
          sx={{ margin: "0.25rem 1rem" }}
        >
          No layers selected
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          position: "absolute",
          bottom: 0,
          width: "100%",
          background: "white",
        }}
      >
        <Button size="small" variant="contained" type="submit">
          Next
        </Button>
      </Box>
    </form>
  );
};

AddServiceStep.propTypes = {
  activeStep: PropTypes.number,
  handleNext: PropTypes.func,
  handleLayerSelection: PropTypes.func,
};

export default AddServiceStep;
