import _ from "lodash";

function generateRoutePlanForDay(crewMemberData, lookUpData, mapData) {
  const routePlans = {};

  crewMemberData.day.forEach((dayData) => {
    const parkingId = dayData.parkingId;

    const polygons = dayData.polygon.map((polygonId) => {
      const polygonData = lookUpData.polygons[polygonId];
      return { polygonId, ...polygonData };
    });

    const routePlan = [];
    if (polygons.length === 0) {
      routePlans[dayData.day] = routePlan;
      return;
    }

    // First object's entry point is parking's point
    if (polygons[0].fromParking) {
      routePlan.push([
        lookUpData.parkings[parkingId].point.lon,
        lookUpData.parkings[parkingId].point.lat,
      ]);
      routePlan.push([
        polygons[0]?.entryPoint.lon,
        polygons[0]?.entryPoint.lat,
      ]);
    }
    polygons.forEach((polygon) => {});
    for (let i = 0; i < polygons.length; i++) {
      const polygon = polygons[i];

      // If current polygon's fromParking is true and it's not the first one, add parking's point as entry
      if (polygon.fromParking && i !== 0) {
        routePlan.push([
          lookUpData.parkings[parkingId].point.lon,
          lookUpData.parkings[parkingId].point.lat,
        ]);
      }

      // Add entry and exit points of current polygon if it's not the last one
      if (i !== polygons.length) {
        // If current polygon's toParking is true, add parking's point as exit
        if (polygon.fromParking) {
          routePlan.push([
            lookUpData.parkings[parkingId].point.lon,
            lookUpData.parkings[parkingId].point.lat,
          ]);
          routePlan.push([polygon.entryPoint.lon, polygon.entryPoint.lat]);
        }

        // If current polygon's toParking is false, connect directly to next polygon
        const currentfilteredCords = findCoordinates(
          mapData,
          polygon.polygonId
        );
        const mapCoordinates = _.cloneDeep(currentfilteredCords);
        if (
          mapCoordinates.length > 0 &&
          Array.isArray(mapCoordinates[0]) &&
          mapCoordinates[0].length >= 2
        ) {
          // Determine if we need to reverse the coordinates
          let coordinatesToAdd;
          const entryPointMatchesFirstCoord =
            polygon.entryPoint.lon === mapCoordinates[0][0] &&
            polygon.entryPoint.lat === mapCoordinates[0][1];
          const entryPointMatchesLastCoord =
            polygon.entryPoint.lon ===
              mapCoordinates[mapCoordinates.length - 1][0] &&
            polygon.entryPoint.lat ===
              mapCoordinates[mapCoordinates.length - 1][1];

          if (entryPointMatchesFirstCoord) {
            coordinatesToAdd = mapCoordinates;
          } else if (entryPointMatchesLastCoord) {
            coordinatesToAdd = mapCoordinates.slice().reverse(); // Use slice() to avoid mutating original
          } else {
            // Handle cases where entry point doesn't match first or last coordinate
            coordinatesToAdd = []; // This case needs specific handling based on expected behavior
          }

          // Add filtered/matched coordinates to the route plan
          coordinatesToAdd.forEach((coord) => {
            if (coord.length >= 2) {
              // Ensure each coordinate is valid
              routePlan.push(coord);
            }
          });

          if (polygon.toParking) {
            routePlan.push([polygon.exitPoint.lon, polygon.exitPoint.lat]);
            routePlan.push([
              lookUpData.parkings[parkingId].point.lon,
              lookUpData.parkings[parkingId].point.lat,
            ]);
          }
        }
      }
    }

    const lastPolygon = polygons[polygons.length - 1];
    if (lastPolygon?.toParking) {
      routePlan.push([lastPolygon.exitPoint.lon, lastPolygon.exitPoint.lat]);
      routePlan.push([
        lookUpData.parkings[parkingId].point.lon,
        lookUpData.parkings[parkingId].point.lat,
      ]);
    }

    routePlans[dayData.day] = routePlan;
  });

  return routePlans;
}

function findCoordinates(mapData, polygonId) {
  const feature = mapData.features.find(
    (feature) => feature.properties.pathId == polygonId
  );
  if (feature) {
    if (feature.geometry.type === "LineString") {
      return feature.geometry.coordinates;
    } else if (feature.geometry.type === "GeometryCollection") {
      let lineStringCoordinates = [];
      feature.geometry.geometries.forEach((geometry) => {
        if (geometry.type === "LineString") {
          lineStringCoordinates = lineStringCoordinates.concat(
            geometry.coordinates
          );
        }
      });
      return lineStringCoordinates;
    }
  }
  return [];
}

// Function to add route plan to crewMemberDayWise data for each day
export function generateRoutePlanForAnimation(lookUpData, mapData) {
  const updatedData = _.cloneDeep(lookUpData);

  for (const crewMemberId in updatedData?.crewMemberDayWise) {
    const crewMemberData = updatedData.crewMemberDayWise[crewMemberId];
    const routePlans = generateRoutePlanForDay(
      crewMemberData,
      lookUpData,
      mapData
    );
    crewMemberData.day.forEach((dayData) => {
      dayData.routePlanForAnimation = routePlans[dayData.day];
    });
  }

  return updatedData;
}

// Log updated data
