import { Typography, Stack } from "@mui/material";
import { isColorDark } from "../../../utils/colorFunctions";
import { PiMapPinAreaFill } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";
import StatusBadge from "./StatusBadge";

const PropertyItem = ({
  property,
  setSelectedProperty,
  isSelected,
  setSelectedPropertyPlanner,
}) => {


  return (
    <div
      className={`propertyItem ${isSelected ? "selected" : ""}`}
      onClick={() => {
        setSelectedProperty(property);
        setSelectedPropertyPlanner({});
      }}
    >
      <div
        style={{
          backgroundColor: isSelected ? "#eaffd8" : "",
          padding: "0.25rem",
          height: "calc(100% - 0.5rem)",
          borderRadius: "0.5rem 0 0 0.5rem",
          display: "grid",
          placeContent: "center",
        }}
      >
        {property.fileName ? (
          <img
            src={
              "https://dev-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/" +
              property.fileName
            }
            alt="Map"
            height={150}
            width={120}
          />
        ) : (
          <img src="/images/image.png" alt="Map" />
        )}
      </div>
      <div style={{ flex: 1, padding: "0.5rem", alignSelf: "flex-start" }}>
        <Stack direction="row" gap={1} justifyContent="space-between">
          <Typography variant="body2" component="h6" fontWeight={500}>
            {property.site}
          </Typography>
          <StatusBadge status={property.status} />
        </Stack>
        <hr
          style={{
            border: "none",
            borderTop: isSelected ? "2px solid #62D100" : "2px solid #FAFAFA",
          }}
        />
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <p style={{
            display: "flex",
            gap: "0.5rem",
            maxWidth: "10rem",
            margin: 0
          }}>
            <PiMapPinAreaFill
              size={15}
              color={isSelected ? "white" : "#27935A"}
              style={{
                flexShrink: 0,
              }}
            />
            <Typography
              flex={1}
              variant="caption"
              component="span"
              sx={{
                lineHeight: "1rem",
                // max two lines
                overflow: "hidden",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                lineClamp: "2",
                "-webkit-box-orient": "vertical",
              }}
            >
              {property.address}
            </Typography>
          </p>
          <span
            style={{
              borderRight: isSelected
                ? "2px solid #62D100"
                : "2px solid #fafafa",
              alignSelf: "stretch",
              flex:"0.5"
            }}
          />
          <Stack flex={1} alignItems="flex-start">
            <Stack direction="row" gap={1}>
              <img
                src={isSelected ? "/images/area.svg" : "/images/area1.svg"} // Default to area1.svg
                alt="Map"
                className="propertyMap"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: isSelected ? "#4EA403" : "white",
                }}
              />
              <Typography variant="caption" component="span">
                Total Area
              </Typography>
            </Stack>
            <Typography
              variant="caption"
              component="span"
              sx={{
                mt: "-0.25rem",
                ml: "1.5rem",
              }}
            >
            <strong>{(property.area / 4046.85642).toFixed(2)}</strong> acres
            </Typography>
          </Stack>
        </Stack>
        <hr
          style={{
            border: "none",
            borderTop: isSelected ? "2px solid #62D100" : "2px solid #FAFAFA",
          }}
        />
        {property.status === "READY_TO_PLANNED" &&
          property.layers.length > 0 && (
            <>
              <Stack
                direction="row"
                gap={1}
                flexWrap="wrap"
                alignItems="center"
              >
                {property.layers.slice(0, 4).map((layer) => {
                  const baseColor = layer.color ?? "#fff";
                  const shouldPreferWhiteLabel = isColorDark(baseColor);
                  return (
                    <div
                      key={layer.layerName}
                      style={{
                        backgroundColor: baseColor,
                        color: layer.color
                          ? shouldPreferWhiteLabel
                            ? "black"
                            : "white"
                          : "#5a5a5a",
                        border: layer.color ? "none" : "1px solid #858585",
                      }}
                      className="metric"
                    >
                      {layer.layerName}: {layer?.totalMeasuredQty?.toFixed(2)}{" "}
                      {layer.unit}
                    </div>
                  );
                })}
                {property.length > 4 && (
                  <Typography variant="caption" component="span">
                    +{property.layers.length - 4} more
                  </Typography>
                )}
              </Stack>
              <hr
                style={{
                  border: "none",
                  borderTop: isSelected
                    ? "2px solid #62D100"
                    : "2px solid #FAFAFA",
                }}
              />
            </>
          )}
        <Typography variant="caption" component="p">
          Created At{" "}
          {new Date(property.createdAt).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })}
        </Typography>
      </div>
      <IoIosArrowForward />
    </div>
  );
};

export default PropertyItem;
