import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useUpdateEquipmentQuantityMutation } from "../../../services/OpenApi";
import { PiBagSimpleBold } from "react-icons/pi";
import { getCustomColor } from "../../../utils/theme";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import { useSelector } from "react-redux";

const EquipmentTable = ({ equipmentData, isGetApiLoading }) => {
  const [updatedQuantities, setUpdatedQuantities] = useState({});
  const greenShade1 = getCustomColor("greenShade1");
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const planningInputData = useSelector(
    (state) => state.planningDashboard.planningInputData
  );
  const [updateEquipmentQuantity] = useUpdateEquipmentQuantityMutation();

  const handleQuantityChange = async (id, newQuantity) => {
    try {
      setUpdatedQuantities((prev) => ({
        ...prev,
        [id]: newQuantity,
      }));
      await updateEquipmentQuantity({
        id,
        quantity: newQuantity,
        organisationName: user?.organization,
      }).unwrap();
    } catch (error) {
      console.error("Error updating equipment quantity:", error);
    }
  };
  return (
    <div className="equipmentTableSection">
      <div className="sectionHeader">
        <div className="sectionHeaderLeft">
          <PiBagSimpleBold fontSize={26} />
          <Typography
            fontWeight="600"
            sx={{ color: blackShade1.shade1, fontSize: "16px" }}
            component="span"
            variant="h6"
          >
            Equipments
          </Typography>
        </div>
      </div>
      <TableContainer
        className="equipmentTableContainer"
        component={Paper}
        sx={{ boxShadow: "none", maxHeight: "20vh" }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#8FE445", height: "10px" }}>
            <TableRow>
              <StyledTableCell style={{ paddingLeft: "20px" }}>
                Equipment Needed
              </StyledTableCell>
              <StyledTableCell
                style={{ textAlign: "center", paddingRight: "0px" }}
              >
                Production Rate
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Number of equipment
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isGetApiLoading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <TableRow key={index}>
                  <StyledTableCell>
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={150}
                      height={25}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "right" }}>
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={100}
                      height={25}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "right" }}>
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={150}
                      height={25}
                    />
                  </StyledTableCell>
                </TableRow>
              ))
            ) : equipmentData?.length > 0 ? (
              equipmentData?.map((equipment) => (
                <TableRow key={equipment.id}>
                  <StyledTableCell
                    sx={{
                      maxWidth: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    style={{ paddingLeft: "20px" }}
                  >
                    {equipment.name}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      maxWidth: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    style={{ paddingLeft: "0px", textAlign: "center" }}
                  >
                    {equipment.productionRate}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      padding: "5px 10px",
                      width: "100px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {planningInputData?.currentStatus === "NOT_SOLVING" && (
                        <button
                          className="decrementButton"
                          onClick={() => {
                            const currentQuantity =
                              updatedQuantities[equipment.id] ??
                              equipment.quantity;
                            if (currentQuantity > 1) {
                              handleQuantityChange(
                                equipment.id,
                                currentQuantity - 1
                              );
                            }
                          }}
                        >
                          -
                        </button>
                      )}
                      <span
                        style={{
                          fontFamily: "Albert Sans",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "22.4px",
                          margin: "0 10px",
                          transition: "margin 0.2s ease",
                        }}
                      >
                        {updatedQuantities[equipment.id] ?? equipment.quantity}
                      </span>
                      {planningInputData?.currentStatus === "NOT_SOLVING" && (
                        <button
                          className="decrementButton"
                          onClick={() => {
                            const currentQuantity =
                              updatedQuantities[equipment.id] ??
                              equipment.quantity;
                            if (currentQuantity < 5) {
                              handleQuantityChange(
                                equipment.id,
                                currentQuantity + 1
                              );
                            }
                          }}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={3} style={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "13px",
                      color: "rgb(95, 97, 99)",
                    }}
                  >
                    No Equipment added
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EquipmentTable;
