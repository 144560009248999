import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  convertProductionUnitRate,
  convertTransitionPace,
} from "../../utils/common";
import { getCustomColor } from "../../utils/theme";
import { equipmentsDummy } from "../../utils/defaultVars";
import { useLazyGetLayerEquipmentsQuery } from "../../services/OpenApi";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";

const AddEquipmentStep = ({ 
  readOnly = false,
  activeStep,
  handleBack,
  handleNext,
  selectedEquipment,
  orgName
}) => {
  const [defaultEquipments, setDefaultEquipments] = useState([]);
  const [getLayerEquipments] = useLazyGetLayerEquipmentsQuery();
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      equipments: [],
    },
  });

  const onSubmit = async (data) => {
    let stepValid = false;
    stepValid = await trigger(["equipments"]);
    if (stepValid) {
      handleNext({
        equipment: data?.equipments, // Pass selected service ID as coreServiceId
        from: "equipment",
      });
    }
  };

  useEffect(() => {
    if(!defaultEquipments.length) return;
    if (
      selectedEquipment
    ) {
      let temp = selectedEquipment.map(
        (equipment) => {
          const matchedEquipment = defaultEquipments.find((x) => x.v2CoreEquipmentId === equipment.v2CoreEquipmentId)
          if(!equipment.isNew)
            return {
              ...equipment,
              productionUnits: matchedEquipment?.productionUnits || [],
              transitionPaceUnits: matchedEquipment?.transitionPaceUnits || [],
              equipmentName: matchedEquipment?.equipmentName || "",
            };
          return {
            ...equipment,
            productionUnits: equipment?.productionUnits || [],
            transitionPaceUnits: equipment?.transitionPaceUnits || [],
            equipmentName: equipment.equipmentName || "",
          }
        }
      );
      setValue("equipments", temp);
    }
  }, [selectedEquipment, setValue, defaultEquipments]);

  const fetchEquipments = async () => {
    try {
      const response = await getLayerEquipments(orgName);
      if (response?.data) {
        const result = response?.data?.data?.map((equipment) => {
          return {
            ...equipment,
            productionUnits: [
              {
                name: "sqft/hr",
                value: "SQUARE_FEET_PER_HOUR",
              },
              {
                name: "acre/hr",
                value: "ACRES_PER_HOUR",
              },
              {
                name: "Hectare/hr",
                value: "HECTARE_PER_HOUR",
              },
              {
                name: "ft/hr",
                value: "FEET_PER_HOUR",
              },
            ],
            transitionPaceUnits: [
              {
                name: "ft/hr",
                value: "FEET_PER_HOUR",
              },
              {
                name: "mph",
                value: "MILES_PER_HOUR",
              },
              {
                name: "kms/hr",
                value: "KILOMETERS_PER_HOUR",
              }
            ],
          };
        });
        setDefaultEquipments(result);
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      toast.error("something went wrong", {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    fetchEquipments();
  }, []);

  const handleEquipmentDelete = (v2CoreEquipmentId) => {
    const existingEquipments = getValues(
      `equipments`
    );
    const newList = existingEquipments.filter(
      (item) => item.v2CoreEquipmentId !== v2CoreEquipmentId
    );
    setValue("equipments", newList);
  };
  const handleAddNewEquipment = () => {
    const existingEquipments = getValues(
      `equipments`
    );
    const newList = [
      ...existingEquipments,
      {
        equipmentName: "",
        productionRate: 0,
        productionRateUnit: "SQUARE_FEET_PER_HOUR",
        transitionPace: 1,
        transitionPaceUnit: "FEET_PER_HOUR",
        productionUnits: [
          {
            name: "sqft/hr",
            value: "SQUARE_FEET_PER_HOUR",
          },
          {
            name: "acre/hr",
            value: "ACRES_PER_HOUR",
          },
          {
            name: "Hectare/hr",
            value: "HECTARE_PER_HOUR",
          },
        ],
        transitionPaceUnits: [
          {
            name: "ft/hr",
            value: "FEET_PER_HOUR",
          }
        ],
        isNew: true,
        // setting the id to a new date to avoid conflicts
        // this will be replaced with the actual id from the api response
        v2CoreEquipmentId: +new Date(),
      }
    ]
    setValue("equipments", newList);
  }
  const handleEquipmentChange = (e, field) => {
    const existingEquipments = getValues(`equipments`);
    const clickedItem = e.target.value[e.target.value.length - 1];
    const isSelected = (existingEquipments|| []).some(
      (item) => item.v2CoreEquipmentId === clickedItem.v2CoreEquipmentId
    );
    const newValue = isSelected
      ? existingEquipments.filter(
          (item) => item.v2CoreEquipmentId !== clickedItem.v2CoreEquipmentId
        )
      : [
          ...(existingEquipments|| []),
          defaultEquipments.find(
            (equipment) =>
              equipment.v2CoreEquipmentId === clickedItem.v2CoreEquipmentId
          ),
        ];
    field.onChange(newValue);
    setTimeout(() => trigger("equipments"), 0);
  };

  const handleProductionUnitChange = (index, unit) => {
    const currentProductionRate = getValues(
      `equipments[${index}].productionRate`
    );
    const currentProductionRateUnit = getValues(
      `equipments[${index}].productionRateUnit`
    );

    const newRate = convertProductionUnitRate(
      currentProductionRate,
      currentProductionRateUnit,
      unit
    );

    setValue(`equipments[${index}].productionRate`, newRate);
    setValue(`equipments[${index}].productionRateUnit`, unit);
  };

  const handleTransitionUnitChange = (index, unit) => {
    const currentTransitionPace = getValues(
      `equipments[${index}].transitionPace`
    );
    const currentTransitionPaceUnit = getValues(
      `equipments[${index}].transitionPaceUnit`
    );

    const newPace = convertTransitionPace(
      currentTransitionPace,
      currentTransitionPaceUnit,
      unit
    );

    setValue(`equipments[${index}].transitionPace`, newPace);
    setValue(`equipments[${index}].transitionPaceUnit`, unit);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}
      style={{flex: 1, position: "relative", display: "flex", flexDirection: "column"}}
    >
      <Controller
        name="equipments"
        control={control}
        rules={{ required: "Equipment is required" }}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.equipments) && (field.value?.length || 0) < 1}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontWeight="600"
                sx={{ color: blackShade1.light }}
                variant="body1"
              >
                Select equipment
              </Typography>
              {
                !readOnly && (
                <Typography
                  fontWeight="500"
                  sx={{ color: greenShade1.shade1, cursor: "pointer" }}
                  variant="body1"
                  onClick={handleAddNewEquipment}
                >
                  Add new equipment
                </Typography>
              )}
            </Box>
            <Select
              inputProps={{ 'aria-label': 'Without label' }}
              multiple
              {...field}
              value={field.value || []}
              onChange={(e) => handleEquipmentChange(e, field)}
              size="small"
              error={
                Boolean(errors.equipments) && (field.value?.length || 0) < 1
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, alignItems: "center" }}>
                  {selected.filter(item=>!item.isNew).slice(0,2).map((value) => (
                    <Chip
                      key={value.equipmentName}
                      label={value.equipmentName.slice(0,20)+(value.equipmentName.length > 20 ? "...":"")}
                    />
                  ))}
                  {selected.filter(item=>!item.isNew).length > 2 && 
                    <Typography component="span" variant="body2">
                      {`+${selected.filter(item=>!item.isNew).length - 2} more`}
                    </Typography>
                  }
                </Box>
              )}
              MenuProps={{
                sx: {
                  maxHeight: "18rem",
                  maxWidth: "30rem",
                },
                PopoverClasses: {
                  paper: "modern-scroll"
                }
              }}
              disabled={readOnly}
            >
              {defaultEquipments &&
                defaultEquipments.map((equipment) => (
                  <MenuItem
                    key={equipment.v2CoreEquipmentId}
                    value={equipment}
                    selected={(field.value || []).some(
                      (selected) =>
                        selected.v2CoreEquipmentId ===
                        equipment.v2CoreEquipmentId
                    )}
                    style={{
                      backgroundColor: (field.value || []).some(
                        (selected) =>
                          selected.v2CoreEquipmentId ===
                          equipment.v2CoreEquipmentId
                      )
                        ? "rgba(0, 0, 0, 0.08)"
                        : "transparent",
                    }}
                  >
                    {equipment.equipmentName}
                  </MenuItem>
                ))}
            </Select>

            {errors.equipments && (
              <FormHelperText>{errors.equipments.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Box className="equipmentWrapper">
      {(watch("equipments") || []).map((equipment, index) => (
        <Box
          className="equipmentContainer"
          sx={{
            border: `solid 1px ${greenShade1.main}`,
          }}
          key={equipment.v2CoreEquipmentId}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            >
              {
                equipment.isNew ?
                <Controller
                  name={`equipments[${index}].equipmentName`}
                  control={control}
                  rules={{
                    required: "Equipment Name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Equipment Name"
                      error={!!errors.equipments?.[index]?.equipmentName}
                      helperText={
                        errors.equipments?.[index]?.equipmentName?.message ||
                        ""
                      }
                      required
                      sx={{width: "100%"}}
                    />
                  )}
                />
                :
                <Typography
                  fontWeight={"700"}
                  sx={{ color: greenShade1.shade1 }}
                  component="span"
                  variant="body1"
                  >
                  {equipment.equipmentName}
                </Typography>
              }
              {
                !readOnly && (
                <IconButton
                  onClick={() => handleEquipmentDelete(equipment.v2CoreEquipmentId)}
                  size="small"
                >
                  <MdDelete />
                </IconButton>
              )}
          </Stack>
          <div className="equipmentDataContainer">
              <div className="serviceInputContainer">
                <Controller
                  name={`equipments[${index}].productionRate`}
                  control={control}
                  rules={{
                    required: "Production Rate is required",
                    min: {
                      value: 0,
                      message: "Minimum value should be 0",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="number"
                      label="Production Rate"
                      error={!!errors.equipments?.[index]?.productionRate}
                      helperText={
                        errors.equipments?.[index]?.productionRate?.message ||
                        ""
                      }
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.00000000000000000001,
                        },
                      }}
                      sx={{width: "100%"}}
                      disabled={readOnly}
                    />
                  )}
                />
                <Controller
                  name={`equipments[${index}].productionRateUnit`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      sx={{ width: "120px" }}
                      onChange={(e) =>
                        handleProductionUnitChange(index, e.target.value)
                      }
                      disabled={readOnly}
                    >
                      {equipment.productionUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
            </div>
              <div className="serviceInputContainer">
                <Controller
                  name={`equipments[${index}].transitionPace`}
                  control={control}
                  rules={{
                    required: "Top Speed is required",
                    min: {
                      value: 1,
                      message: "Minimum value should be 1",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="number"
                      label="Top Speed"
                      error={!!errors.equipments?.[index]?.transitionPace}
                      helperText={
                        errors.equipments?.[index]?.transitionPace?.message ||
                        ""
                      }
                      InputProps={{
                        inputProps: {
                          min: 1,
                          step: 0.00000000000000000001,
                        },
                      }}
                      sx={{width: "100%"}}
                    />
                  )}
                  disabled={readOnly}
                />
                <Controller
                  name={`equipments[${index}].transitionPaceUnit`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      sx={{ width: "120px" }}
                      onChange={(e) =>
                        handleTransitionUnitChange(index, e.target.value)
                      }
                      disabled={readOnly}
                    >
                      {equipment.transitionPaceUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
          </div>
        </Box>
      ))}
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          background: "white",
          width: "100%",
          gap: "10px",
          bottom: 0,
          zIndex: 111
        }}
      >
        <Button size="small" variant="contained" onClick={handleBack}>
          Prev
        </Button>
        <Button size="small" variant="contained" type="submit">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default AddEquipmentStep;
