import React, { useEffect, useRef, useState } from "react";
import AddServiceLayerModal from "../../Modals/AddServiceLayerModal/AddServiceLayerModal";
import { PiWrenchBold } from "react-icons/pi";
import "./PlanningDashboardSidebarSummary.css";
import { Typography, TableCell, styled, tableCellClasses } from "@mui/material";
import { getCustomColor } from "../../utils/theme";
import { useLocation } from "react-router-dom";
import ServicesTable from "./Components/ServicesTable";
import CrewMemberTable from "./Components/CrewMemberTable";
import EquipmentTable from "./Components/EquipmentTable";
import { useSelector } from "react-redux";
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e0ffc4",
    color: "#8FE445",
    fontWeight: 600,
    fontSize: "12px",
    height: "10px",
    padding: "0px 6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
  whiteSpace: "nowrap",
}));

export const PlanningDashboardSidebarSummary = ({
  runPlannerData,
  isLoading,
}) => {
  const [isAddServiceLayerModalOpen, setIsAddServiceLayerModalOpen] =
    useState(false);
  const blackShade1 = getCustomColor("blackShade1");
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const planningInputData = useSelector(
    (state) => state.planningDashboard.planningInputData
  );

  const servicesGetData = Array.isArray(
    runPlannerData?.data?.runPlannerServices
  )
    ? runPlannerData?.data?.runPlannerServices
    : [];
  const equipmentData = Array.isArray(runPlannerData?.data?.equipmentData)
    ? runPlannerData?.data?.equipmentData
    : [];
  const crewMembersList = Array.isArray(runPlannerData?.data?.crewMember)
    ? runPlannerData?.data?.crewMember
    : [];

  const [isEditServiceModalOpen, setIsEditServiceModalOpen] = useState(false);
  const tempRef = useRef(null);

  return (
    <div
      className="content"
      id="sidebarDrawer"
      style={{
        position: "relative",
        flex: 1,
      }}
      ref={tempRef}
    >
      <AddServiceLayerModal
        isOpen={isAddServiceLayerModalOpen}
        handleClose={() => setIsAddServiceLayerModalOpen(false)}
        ref={tempRef}
      />

      <div className="ServicesTableSection">
        <div className="sectionHeader">
          <div className="sectionHeaderLeft">
            <PiWrenchBold fontSize={26} />
            <Typography
              fontWeight="600"
              sx={{ color: blackShade1.shade1, fontSize: "16px" }}
              component="span"
            >
              Services Details
            </Typography>
          </div>
          {planningInputData?.currentStatus === "NOT_SOLVING" && (
            <button
              className="addButton"
              onClick={() => setIsAddServiceLayerModalOpen(true)}
            >
              Add Service Layer
            </button>
          )}
        </div>

        <ServicesTable
          servicesGetData={servicesGetData}
          isDataLoading={isLoading}
          isEditServiceModalOpen={isEditServiceModalOpen}
          setIsEditServiceModalOpen={setIsEditServiceModalOpen}
          ref={tempRef}
        />
      </div>
      {/* Crew Member Section */}
      <CrewMemberTable
        crewMembersList={crewMembersList}
        isGetApiLoading={isLoading}
      />

      {/* Equipment Section */}
      <EquipmentTable
        equipmentData={equipmentData}
        isGetApiLoading={isLoading}
      />
      {/* custom backdrop because MUI backdrop covers the whole page */}
      {(isAddServiceLayerModalOpen || isEditServiceModalOpen) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(50, 103, 3, 0.75)",
            zIndex: 100,
          }}
        />
      )}
    </div>
  );
};
