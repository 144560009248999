import { Typography } from "@mui/material";
import React from "react";

const ColumnHeader = ({ children }) => {
  return (
    <Typography
      variant="caption"
      component="h2"
      style={{
        color: "#616965",
        textTransform: "uppercase",
        padding: "0.25rem 1rem",
        borderBottom: "1px solid #BDC0BE",
      }}
    >
      {children}
    </Typography>
  );
};

export default ColumnHeader;
