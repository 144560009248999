import _ from "lodash";
import { hexToRgb } from "./common";

export function calculateTotalAssignmentsForCrewMember(data) {
  const updatedData = _.cloneDeep(data);

  for (const crewId in updatedData?.crewMemberWise) {
    let crewMember = updatedData.crewMemberWise[crewId];
    let totalAssignTravelTime = 0;
    let totalAssignTimeOfWork = 0;
    let totalAssignTravelDistance = 0;
    for (const polygonId of crewMember.polygons) {
      const polygon = updatedData.polygons[polygonId];
      if (polygon && polygon.crewMemberId === parseInt(crewId)) {
        totalAssignTravelTime += polygon.travelTimeInSec;
        totalAssignTimeOfWork += polygon.durationInSec;
        totalAssignTravelDistance += polygon.travelDistance;
      }
    }

    // Update crewMembers with the calculated values
    updatedData.crewMembers[crewId].totalAssignTravelTime =
      totalAssignTravelTime;
    updatedData.crewMembers[crewId].totalAssignTimeOfWork =
      totalAssignTimeOfWork;
    updatedData.crewMembers[crewId].totalAssignTravelDistance =
      totalAssignTravelDistance;
  }

  return updatedData;
}

export function calculateServicesTotalForPlanning(data) {
  const serviceTotals = {};

  // Calculate total travel time and total area for each service
  for (const polygonId in data?.polygons) {
    const polygon = data.polygons[polygonId];
    const serviceId = polygon?.serviceId;
    const work = polygon?.durationInSec;
    const travel = polygon?.travelTimeInSec;
    const measurement = polygon?.measurement;


    if (!serviceTotals[serviceId]) {
      serviceTotals[serviceId] = {
        workTime: 0,
        travelTime: 0,
        totalArea: 0,
      };
    }

    serviceTotals[serviceId].workTime += work;
    serviceTotals[serviceId].travelTime += travel;
    serviceTotals[serviceId].totalArea += measurement;
  }

  // Add workTime and totalArea to the corresponding service
  for (const serviceId in serviceTotals) {
    if (data.services[serviceId]) {
      data.services[serviceId].workTime = serviceTotals[serviceId].workTime;
      data.services[serviceId].travelTime = serviceTotals[serviceId].travelTime;
      data.services[serviceId].totalArea = serviceTotals[serviceId].totalArea;
    }
  }
  return data;
}

function getLonLatFromPoint(point) {
  if (point?.lon && point?.lat)
    return [point.lon, point.lat];
  return [0, 0];
}
export function routeGeneration(crewMemberId, crewMemberPolygons, lookUpData) {
  if (!lookUpData || !crewMemberPolygons || !crewMemberId) return []
  const routePlan = [];

  // populate comingPolygons with data from lookUp
  const polygons = crewMemberPolygons.map((polygonId) => lookUpData?.polygons[polygonId]);
  const crewMemberParking = lookUpData.crewMembers[crewMemberId]?.parking;
  const crewMemberColor = hexToRgb(lookUpData.crewMembers[crewMemberId]?.color);
  const crewMemberParkingPoint = lookUpData?.parkings[crewMemberParking]?.point;

  // Iterate through each polygon
  for (let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i];
    const prevPolygon = i - 1 >= 0 ? polygons[i - 1] : null

    // If current polygon's fromParking is true
    if (polygon?.fromParking) {
      // if prevPolygon exists, add route for prev polygon to parking
      if (prevPolygon)
        routePlan.push({
          source: getLonLatFromPoint(prevPolygon ? prevPolygon.centerPoint : polygon.centerPoint),
          target: getLonLatFromPoint(crewMemberParkingPoint),
          color: crewMemberColor,
        });
      // start from parking to polygon
      routePlan.push({
        source: getLonLatFromPoint(crewMemberParkingPoint),
        target: getLonLatFromPoint(polygon.centerPoint),
        color: crewMemberColor,
      });
    } else {
      routePlan.push({
        source: getLonLatFromPoint(prevPolygon ? prevPolygon.centerPoint : polygon.centerPoint),
        target: getLonLatFromPoint(polygon.centerPoint),
        color: crewMemberColor,
      });
    }
  }

  // connect last point back to parking
  if (crewMemberParkingPoint && polygons.length > 0) {
    routePlan.push({
      source: getLonLatFromPoint(polygons[polygons.length - 1].centerPoint),
      target: getLonLatFromPoint(crewMemberParkingPoint),
      color: crewMemberColor,
    });
  } else console.error("Parking Point not found for crewMemberId", crewMemberId);

  return routePlan;
}