import { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Typography, Skeleton } from "@mui/material";
import ColumnHeader from "./ColumnHeader";
import PropertyItem from "./PropertyItem";
import { useGetPropertiesQuery } from "../../../services/OpenApi";

const PropertiesColumn = ({
  selectedProperty,
  setSelectedProperty,
  setSelectedPropertyPlanner,
  ref,
  refreshData
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [skeletonCount, setSkeletonCount] = useState(2);
  const [properties, setProperties] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const loaderRef = useRef(null);
  const containerRef = useRef(null);
  useEffect (() => {
    if(refreshData){
      setProperties([])
    }
  },[refreshData])
  const { data, isLoading, isError, error } = useGetPropertiesQuery({
    page: currentPage,
    orgName: user?.organization,
  });

  useEffect(() => {
    const updateSkeletons = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const skeletonHeight = 200;
        const newSkeletonCount = Math.max(
          Math.floor(containerHeight / skeletonHeight),
          2,
        );
        setSkeletonCount(newSkeletonCount);
      }
    };
    updateSkeletons();
    window.addEventListener("resize", updateSkeletons);
    return () => window.removeEventListener("resize", updateSkeletons);
  }, []);

  useEffect(() => {
    if (data?.data?.content) {
      setProperties((prevProperties) => [
        ...prevProperties,
        ...data?.data?.content,
      ]);
    }
  }, [data]);


  useEffect(() => {
    if (!loaderRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !isError) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 },
    );

    observer.observe(loaderRef.current);

    return () => observer.disconnect();
  }, [loaderRef, isLoading, isError]);
  return (
    <div style={{ width: "40%" }} ref={containerRef}>
      <ColumnHeader>Properties</ColumnHeader>
      <div
        className="modern-scroll"
        style={{
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          height: "calc(100vh - 8vh - 12.85rem)",
          overflowY: "auto",
        }}
      >
        {isError && (
          <Typography variant="caption" component="p" color="error">
            Error:{" "}
            {error ? error.message || JSON.stringify(error) : "Unknown error"}
          </Typography>
        )}
        {properties.length > 0
          ? properties.map((property) => (
              <PropertyItem
                key={property.id}
                property={property}
                setSelectedProperty={setSelectedProperty}
                isSelected={property.id === selectedProperty?.id}
                setSelectedPropertyPlanner={setSelectedPropertyPlanner}
              />
            ))
          : !isLoading && (
              <Typography variant="body2" color="textSecondary">
                No properties available.
              </Typography>
            )}
        {isLoading &&
          Array.from({ length: skeletonCount }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={200}
              width="100%"
              animation="wave"
            />
          ))}
        <div ref={loaderRef} />
      </div>
    </div>
  );
};

export default PropertiesColumn;
