import { Checkbox, Drawer, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setServiceWise,
  setShowArrowMarkers,
  setShowConnectingLines,
  setShowNumberCircles
} from "../../../slices/planningDashboardSlice";
import { IoClose } from "react-icons/io5";
import { AiOutlineControl } from "react-icons/ai";
import { TiArrowSortedDown } from "react-icons/ti";

const PlanningMapControlsDrawer = () => {
  const serviceWise = useSelector(
    (state) => state.planningDashboard.serviceWise
  );
  const showNumberCircles = useSelector(
    (state) => state.planningDashboard.showNumberCircles
  );
  const showArrowMarkers = useSelector(
    (state) => state.planningDashboard.showArrowMarkers
  );
  const showConnectingLines = useSelector(
    (state) => state.planningDashboard.showConnectingLines
  );
  const dispatch = useDispatch();
  const metaData = JSON.parse(localStorage.getItem("metaData"));

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleMapControlsDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <button
        onClick={() => setIsExpanded(true)}
        style={{
          background: "#fff",
          borderRadius: "0.25rem",
          border: "solid 1px #dedede",
          padding: "0.5rem 1rem",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer"
        }}
      >
        <span
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <AiOutlineControl />
          <Typography
            variant="body2"
            component="span"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Map Controls
          </Typography>
        </span>
        <TiArrowSortedDown />
      </button>
      <Drawer
        anchor="top"
        open={isExpanded}
        hideBackdrop
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "0 0 10px 10px",
            width: 220,
            position: "fixed",
            bottom: 0,
            top: "8vh",
            right: 10,
            left: "auto",
            zIndex: "1",
            maxHeight: "35vh",
          },
        }}
      >
        <div className="drawerWrapper">
          <div className="mainContainer">
            <div className="RightControls">
              <div className="mapControlsTitleContainer">
                <div className="controlsTitle">Map Controls</div>
                <div className="closeButton">
                  <IoClose size={24} onClick={toggleMapControlsDrawer} />
                </div>
              </div>
              <div className="mapFiltersContainer">
                {/* <div className="mapFilters">
                  <label>Labels</label>
                  <div>
                    {!isSolver && (
                      <Checkbox
                        size="small"
                        checked={showLabels}
                        onChange={(e) =>
                          dispatch(setShowLabels(e.target.checked))
                        }
                      />
                    )}
                  </div>
                </div> */}
                <div className="mapFilters">
                  <label>Service Wise</label>
                  <div>
                      <Checkbox
                        size="small"
                        checked={serviceWise}
                        onChange={(e) =>
                          dispatch(setServiceWise(e.target.checked))
                        }
                      />
                  </div>
                </div>
                {metaData && metaData?.maproute_controls && (
                  <>
                    <div className="mapFilters">
                      <label>Show Number Circles</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showNumberCircles}
                            onChange={(e) =>
                              dispatch(setShowNumberCircles(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    <div className="mapFilters">
                      <label>Direction Arrows</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showArrowMarkers}
                            onChange={(e) =>
                              dispatch(setShowArrowMarkers(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                    <div className="mapFilters">
                      <label>Connecting Lines</label>
                      <div>
                          <Checkbox
                            size="small"
                            checked={showConnectingLines}
                            onChange={(e) =>
                              dispatch(setShowConnectingLines(e.target.checked))
                            }
                          />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PlanningMapControlsDrawer;
