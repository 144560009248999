import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  TextField,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  Box,
  Stack
} from "@mui/material";
import { useAddCrewMemberMutation, useUpdateCrewMemberNameMutation } from "../../services/OpenApi";
import { useLocation } from "react-router-dom";
import ColorPickerButton from "../../components/Buttons/ColorPickerButton";
import { generateRandomColor } from "../../utils/colorFunctions";

const AddCrewMemberModal = ({ isOpen, handleClose, handleAddCrewMember, editData, setCrewMembers, setEditingCrewMember }) => {
  const [crewMemberName, setCrewMemberName] = useState("");
  const [color, setColor] = useState(generateRandomColor);
  const [addCrewMember, { isLoading }] = useAddCrewMemberMutation();
  const [updateCrewMemberName, {isLoading: isEditLoading}] = useUpdateCrewMemberNameMutation();
  const user = JSON.parse(localStorage.getItem("user"));
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const plannerId = searchParams.get("id");

  const handleCancel = () => {
    handleClose();
    setCrewMemberName("");
  }

  // Function to handle crew member submission
  const handleSubmit = async () => {

    try {
      const result = await addCrewMember({
        crewMemberName: crewMemberName,
        color: color,
        plannerId: plannerId,
        organisationName: user?.organization,
      }).unwrap();

      if (result.data) {
        const match = result.data.match(/Id (\d+)/);
        const extractedId = match ? match[1] : null;

        if (extractedId) {
          handleAddCrewMember({
            crewMemberName: crewMemberName,
            id: extractedId,
            color: color,
          });
        } else {
          console.error("Unable to extract ID from response:", result.data);
        }
      }
      setCrewMemberName("");
      handleCancel();
    } catch (error) {
      console.error("Error adding crew member:", error);
    }
  };

  const handleEditCrewMember = async () => {
    try {
      await updateCrewMemberName({
        id: editData?.id,
        color: color,
        organisationName: user?.organization,
        crewMemberName: crewMemberName,
      }).unwrap();

      setCrewMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === editData?.id
            ? { ...member, crewMemberName: crewMemberName, color: color }
            : member
        )
      );

      handleCancel();
      setCrewMemberName("");
      setEditingCrewMember(null);
    } catch (error) {
      console.error("Failed to update crew member:", error);
    }
  };

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleSubmit();
  };

  useEffect(()=>{
    const newColor = generateRandomColor();
    if(editData){
      setCrewMemberName(editData?.crewMemberName)
      setColor(editData?.color)
    } else setColor(newColor)
  },[editData, isOpen])

  return (
    <>
      <Modal open={isOpen} onClose={handleCancel}>
        <div style={modalStyle}>
          <Typography variant="h6">{editData ? "Edit" : "Add"} Crew Member</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center"
            gap={1}
          >
          <TextField
            sx={{ margin: "10px 0" }}
            label="Crew Member Name"
            size="small"
            value={crewMemberName}
            onChange={(e) => setCrewMemberName(e.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
            required
            disabled={isLoading || isEditLoading}
            />
            <ColorPickerButton value={color} setValue={setColor} />
          </Stack>
          <Box sx={{display: "flex", justifyContent: "end", gap: "10px", marginTop: "10px"}}>
            <Button onClick={handleCancel} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              onClick={editData ? handleEditCrewMember : handleSubmit}
              variant="contained"
              color="primary"
              disabled={isLoading || isEditLoading || !crewMemberName.trim()}
            >
              {(isLoading || isEditLoading) && <CircularProgress size={18} color="grey" thickness={5} />} {editData ? "Edit" : "Add"}
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};

const modalStyle = {
  padding: "20px",
  background: "white",
  margin: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  width: "400px",
};

export default AddCrewMemberModal;
