export function isColorDark(bgColor) {
    let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    return ((r * 0.299) + (g * 0.587) + (b * 0.114)) >= 186;
}

export const generateRandomColor = () => {
    const distinctColors = [
        "#f58231",
        "#FF0000", // Red
        "#A52A2A", // Brown
        "#800000", // Maroon
        "#9A6324",
        "#b0de09",
        "#3cb44b",
        "#008000", // Green
        "#808000", // Olive
        "#FFFF00", // Yellow
        "#FFD700", // Gold
        "#FFA500", // Orange
        "#800080", // Purple
        "#FFC0CB", // Pink
        "#C0C0C0", // Silver
        "#808080", // Gray
        "#000000", // Black
        "#46f0f0",
        "#008080", // Teal
        "#0000FF", // Blue
        "#000080", // Navy Blue
        "#4B0082", // Indigo
        "#911eb4",
        "#f032e6", // Violet
    ];
    return distinctColors[Math.floor(Math.random() * distinctColors.length)];
}