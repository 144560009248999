import {
  Box,
  Checkbox,
  CircularProgress,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  convertSecondsToReadable,
  convertTime,
  formatDistance,
  getLengthUnit,
  truncateString,
} from "../../../utils/common";
import SelectPlanModal from "../../../Modals/SelectPlanModal/SelectPlanModal";
import { getCustomColor } from "../../../utils/theme";
import ColorPickerModal from "../../../Modals/ColorPickerModal/ColorPickerModal";
import { PiBagSimpleBold, PiWrenchBold } from "react-icons/pi";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import {
  setPlanningCrewMemberDayWiseFilteredData,
  setPlanningMapGeoJsonFilteredData,
  setPlanningSelectedCrewMembersId,
  setSelectedServices,
} from "../../../slices/planningDashboardSlice";
import { MdTypeSpecimen } from "react-icons/md";
import AddServiceLayerModal from "../../../Modals/AddServiceLayerModal/AddServiceLayerModal";
import { useLazyGetRunPlannerServiceQuery } from "../../../services/OpenApi";
import { IoMdEye } from "react-icons/io";

const PlanningDashboardPlannedData = ({ isSolver = false }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const initialCrewData = useSelector(
    (state) => state.planningDashboard.plannedInitialCrewMembers,
  );
  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [crewMemberColorData, setCrewMemberColorData] = useState(false);
  const selectedServices = useSelector(
    (state) => state.planningDashboard.selectedServices,
  );
  const mapData = useSelector(
    (state) => state.planningDashboard.planningMapGeoJsonData,
  );
  const [selectedCrewMembers, setSelectedCrewMembers] = useState(
    Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
      acc[crewMemberId] = false;
      return acc;
    }, {}),
  );

  useEffect(() => {
    if (mapData) {
      const uniqueServices = {};
      mapData.features.forEach((feature) => {
        const layerType = feature.properties.layerType;
        if (!uniqueServices[layerType]) {
          uniqueServices[layerType] = true;
        }
      });
      dispatch(setSelectedServices(uniqueServices));
    }
  }, [mapData]);
  const dispatch = useDispatch();
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const lookUp = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );

  useEffect(() => {
    if (initialCrewData) {
      setSelectedCrewMembers(
        Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
          acc[crewMemberId] = false;
          return acc;
        }, {}),
      );
    }
  }, [initialCrewData, selectedDay]);

  const getFilteredData = () => {
    return Object.keys(initialCrewData)
      .filter((crewMemberId) => selectedCrewMembers[crewMemberId])
      .reduce((acc, id) => {
        acc[id] = { ...initialCrewData[id] };
        return acc;
      }, {});
  };

  useEffect(() => {
    const filteredData = getFilteredData();
    dispatch(setPlanningCrewMemberDayWiseFilteredData(filteredData));
  }, [selectedCrewMembers, dispatch]);

  useEffect(() => {
    if (mapData && mapData?.features && selectedServices) {
      const filteredFeatures = mapData?.features?.filter(
        (feature) => selectedServices[feature?.properties?.layerType],
      );

      // Construct a new GeoJSON object containing only the filtered features
      const newFilteredGeoJSON = {
        ...mapData,
        features: filteredFeatures,
      };
      dispatch(setPlanningMapGeoJsonFilteredData(newFilteredGeoJSON));
    }
  }, [selectedServices, mapData]);

  const handleCrewMemberCheckboxChange = (id, isChecked) => {
    setSelectedCrewMembers((prev) => ({ ...prev, [id]: isChecked }));
  };
  useEffect(() => {
    dispatch(setPlanningSelectedCrewMembersId(selectedCrewMembers));
  }, [selectedCrewMembers, dispatch]);

  const handleServicesCheckboxChange = (serviceType) => {
    dispatch(setSelectedServices(serviceType));
  };

  const servicesArray = lookUp?.services ? Object.keys(lookUp?.services).map(service=>({
    ...lookUp?.services[service],
    serviceId: service
  })) : [];
  const crewMembersArray = lookUp?.crewMembers
    ? Object.entries(lookUp?.crewMembers)
    : [];
  const equipmentsArray = lookUp?.equipments
    ? Object.entries(lookUp?.equipments)
    : [];

  const handleCrewMemberColor = (crewMemberId, crewMemberColor) => {
    setIsColorPickerOpen(true);
    setCrewMemberColorData({
      crewMemberId: crewMemberId,
      crewMemberColor: crewMemberColor,
    });
  };

  const totalServiceWorkTime = servicesArray?.reduce(
    (total, service) => total + service?.workTime,
    0,
  );
  const totalServiceTravelTime = servicesArray?.reduce(
    (total, service) => total + service?.travelTime,
    0,
  );

  // Calculate total work time, travel time, and distance for crew members
  const totalCrewWorkTime = crewMembersArray?.reduce(
    (total, [, crewMember]) => total + crewMember?.totalAssignTimeOfWork,
    0,
  );
  const totalCrewTravelTime = crewMembersArray?.reduce(
    (total, [, crewMember]) => total + crewMember?.totalAssignTravelTime,
    0,
  );
  const totalCrewDistance = crewMembersArray?.reduce(
    (total, [, crewMember]) => total + crewMember?.totalAssignTravelDistance,
    0,
  );

  const tempRef = useRef(null);
  const [viewServiceLayerModal, setViewServiceLayerModal] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [getRunPlannerService, {isFetching: runPlannerServiceLoading}] = useLazyGetRunPlannerServiceQuery();
  const [viewServiceId, setViewServiceId] = useState(null);
  const handleViewClick = async (id) => {
    setViewServiceId(id);
    try {
      const response = await getRunPlannerService({
        id: id,
        orgName: user?.organization,
      });
      if (response) {
        setViewServiceLayerModal(response?.data?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setViewServiceId(null);
    }
  };

  return (
    <>
      <div className="sidebarDrawer" ref={tempRef}>
        <div className="plannerDetailsContianer">
          {lookUp && (
            <>
              <div className="ServicesTableSection">
                <div className="sectionHeader">
                  <div className="sectionHeaderLeft">
                    <PiWrenchBold fontSize={26} />
                    <Typography
                      fontWeight="600"
                      sx={{ color: blackShade1.shade1, fontSize: "16px" }}
                      component="span"
                    >
                      Services Details
                    </Typography>
                  </div>
                </div>
              </div>
              <TableContainer
                className="serviceTableContainer"
                component={Paper}
                sx={{ boxShadow: "none",height: "calc((100vh - 8vh - 3.2rem - 90px) / 3)"}}
              >
                <Table stickyHeader size="small" aria-label="sticky table">
                  <TableHead
                    sx={{ backgroundColor: "#8FE445", height: "10px" }}
                  >
                    <TableRow sx={{ height: `10px`, padding: "0px 6px" }}>
                      <StyledTableCell
                        sx={{
                          maxWidth: 80,
                          backgroundColor: "#8FE445",
                          color: "white",
                          fontWeight: 600,
                          fontSize: "20px",
                        }}
                        style={{ paddingLeft: "16px" }}
                      >
                        Service
                      </StyledTableCell>
                      <StyledTableCell align="center">Work</StyledTableCell>
                      <StyledTableCell align="center">Travel</StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title="Measurments">Dimensions</Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicesArray.map((service, index) => {
                      const {
                        serviceName,
                        workTime,
                        travelTime,
                        totalArea,
                        color,
                      } = service;
                      const formattedServiceName = serviceName.replace(
                        "_",
                        " ",
                      );
                      return (
                        <TableRow
                          key={workTime * index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <div className="plannerNameContainer">
                              <div className="checkboxContainer">
                                {selectedServices && (
                                  <Checkbox
                                    size="small"
                                    checked={
                                      selectedServices[formattedServiceName]
                                        ? selectedServices[formattedServiceName]
                                        : false
                                    }
                                    onChange={() =>
                                      handleServicesCheckboxChange(
                                        formattedServiceName,
                                      )
                                    }
                                  />
                                )}
                              </div>

                              <div className="serviceNameContainer">
                                <Tooltip title={formattedServiceName}>
                                  <div>
                                    {truncateString(formattedServiceName, 13)}
                                  </div>
                                </Tooltip>
                                <div
                                  className={`Plannercolor`}
                                  style={{ background: color }}
                                />
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {convertSecondsToReadable(workTime?.toFixed(2))}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {convertSecondsToReadable(travelTime?.toFixed(2))}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {totalArea?.toFixed(0)}{" "}
                            {getLengthUnit(formattedServiceName)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            onClick={() => {
                              if(!runPlannerServiceLoading)
                                handleViewClick(service.serviceId)
                            }}
                            style={{
                              padding: "0",
                              cursor: runPlannerServiceLoading?"default":"pointer",
                            }}
                          >
                            {viewServiceId===service?.serviceId ? 
                              <CircularProgress size={14} thickness={5} />
                             : <IoMdEye size={18} color={greenShade1.shade2} />}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <StyledTableCell component="th" scope="row">
                        <Typography
                          fontWeight="500"
                          component="span"
                          variant="subtitle2"
                        >
                          Total ({" "}
                          {convertSecondsToReadable(
                            (
                              totalServiceWorkTime + totalServiceTravelTime
                            ).toFixed(2),
                          )}{" "}
                          )
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {convertSecondsToReadable(
                          totalServiceWorkTime?.toFixed(2),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {convertSecondsToReadable(
                          totalServiceTravelTime?.toFixed(2),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="sectionHeader">
                <div className="sectionHeaderLeft">
                  <PiWrenchBold fontSize={26} />
                  <Typography
                    fontWeight="600"
                    sx={{ color: blackShade1.shade1, fontSize: "16px" }}
                    component="span"
                    variant="h6"
                  >
                    Crew Member Details
                  </Typography>
                </div>
              </div>
              <TableContainer
                className="crewTableContainer"
                component={Paper}
                sx={{ boxShadow: "none", height: "calc((100vh - 8vh - 3.2rem - 90px) / 3)" }}
              >
                <Table stickyHeader size="small" aria-label="sticky table">
                  <TableHead
                    sx={{ backgroundColor: "#8FE445", height: "10px" }}
                  >
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell align="center">Work</StyledTableCell>
                      <StyledTableCell align="center">Travel</StyledTableCell>
                      <StyledTableCell align="center">Distance</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {crewMembersArray
                      .reverse()
                      .map(([crewMemberId, crewMember], index) => {
                        const {
                          name,
                          color,
                          totalAssignTimeOfWork,
                          totalAssignTravelDistance,
                          totalAssignTravelTime,
                        } = crewMember;

                        return (
                          <TableRow
                            key={totalAssignTimeOfWork * index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              <div className="plannerNameContainer">
                                <div className="checkboxContainer">
                                  <Checkbox
                                    checked={selectedCrewMembers[crewMemberId]}
                                    onChange={(e) =>
                                      handleCrewMemberCheckboxChange(
                                        crewMemberId,
                                        e.target.checked,
                                      )
                                    }
                                    size="small"
                                  />
                                </div>
                                <div className="serviceNameContainer">
                                  <Tooltip title={name}>
                                    <div>{truncateString(name, 13)}</div>
                                  </Tooltip>
                                  <Tooltip title="Change Color">
                                    <Box
                                      className={`Plannercolor`}
                                      onClick={() =>
                                        handleCrewMemberColor(
                                          crewMemberId,
                                          color,
                                        )
                                      }
                                      sx={{
                                        backgroundColor: color,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertSecondsToReadable(totalAssignTimeOfWork)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertSecondsToReadable(totalAssignTravelTime)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatDistance(totalAssignTravelDistance)}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    <TableRow>
                      <StyledTableCell component="th" scope="row">
                        <Typography
                          fontWeight="500"
                          component="span"
                          variant="subtitle2"
                        >
                          Total
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {convertSecondsToReadable(
                          totalCrewWorkTime?.toFixed(2),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {convertSecondsToReadable(
                          totalCrewTravelTime?.toFixed(2),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatDistance(totalCrewDistance)}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="equipmentTableSection">
                <div className="sectionHeader">
                  <div className="sectionHeaderLeft">
                    <PiBagSimpleBold fontSize={26} />
                    <Typography
                      fontWeight="600"
                      sx={{ color: blackShade1.shade1, fontSize: "16px" }}
                      component="span"
                      variant="h6"
                    >
                      Equipments
                    </Typography>
                  </div>
                </div>
                <TableContainer
                  className="equipmentTableContainer"
                  component={Paper}
                  sx={{ boxShadow: "none", height: "calc((100vh - 8vh - 3.2rem - 90px) / 3)"}}
                >
                  <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead
                      sx={{ backgroundColor: "#8FE445", height: "10px" }}
                    >
                      <TableRow>
                        <StyledTableCell style={{ paddingLeft: "20px" }}>
                          Equipment Needed
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                        Production Rate
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ textAlign: "center", paddingRight: "0px" }}
                        >
                          Qty
                          </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          Usage
                        </StyledTableCell>
                        
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {equipmentsArray.map(
                        ([equipmentId, equipment], index) => {
                          const { type } = equipment;
                          const { equipmentDurationInSec } = equipment;
                          return (
                            <TableRow key={equipment.id}>
                              <StyledTableCell
                                sx={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                style={{ paddingLeft: "20px" }}
                              >
                                {type}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                style={{
                                  paddingLeft: "0px",
                                  textAlign: "center",
                                }}
                              >
                                --
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  textAlign: "center",
                                  padding: "5px 10px",
                                  width: "100px",
                                }}
                              >
                                --
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {convertSecondsToReadable(equipmentDurationInSec)}
                              </StyledTableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </div>
      <SelectPlanModal
        isOpen={isSelectPlanOpen}
        setIsOpen={setIsSelectPlanOpen}
      />
      <ColorPickerModal
        isOpen={isColorPickerOpen}
        setIsOpen={setIsColorPickerOpen}
        crewMemberColorData={crewMemberColorData}
        isPlanning={true}
      />
      <AddServiceLayerModal
        isOpen={!!viewServiceLayerModal}
        handleClose={() => setViewServiceLayerModal(null)}
        editServiceData={viewServiceLayerModal}
        setEditServiceData={setViewServiceLayerModal}
        method="VIEW"
        ref={tempRef}
      />
      {/* custom backdrop because MUI backdrop covers the whole page */}
      {(viewServiceLayerModal) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(50, 103, 3, 0.75)",
            zIndex: 99,
          }}
        />
      )}
    </>
  );
};

export default PlanningDashboardPlannedData;
