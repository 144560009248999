import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateOrganizationMutation,
  useLazyGetOrganizationDetailsQuery,
} from "../../services/Auth";
import { useNavigate } from "react-router-dom";
import { getCustomColor } from "../../utils/theme";
import AuthDesignWrapper from "../../components/AuthDesignWrapper/AuthDesignWrapper";
import { useCreateOrganisationForSchemaMutation } from "../../services/OpenApi";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const NewUser = () => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      country: "", // Placeholder for the select field
      pincode: "",
    },
  });
  const navigate = useNavigate();
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const [getOrganizationDetails] = useLazyGetOrganizationDetailsQuery();

  const [createOrganisationForSchema, { isLoading: isSchemaLoading }] =
    useCreateOrganisationForSchemaMutation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState(null);

  const onSubmit = async (data) => {
    try {
      const domain = user?.email?.split("@")[1].split(".")[0];
      const url = `https://maps.googleapis.com/maps/api/geocode/json?components=country:${data?.country}|postal_code:${data?.pincode}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

      const response = await fetch(url);
      const dataResponse = await response.json();

      if (dataResponse.results.length === 0) {
        toast.error("Invalid Pincode");
        return;
      }

      const locality = dataResponse.results[0]?.address_components?.find(
        (component) =>
          component?.types?.includes("locality") && component?.long_name
      );

      const formattedArea = `${domain}-${locality?.long_name
        ?.replace(/\s+/g, "_")
        ?.toLowerCase()}`;
      setFormattedAddress(formattedArea);

      try {
        const postData = {
          organisationName: formattedArea,
          userEmail: user?.email.replace("+","%2B"),
          linkWithOrganisation: false,
        };
        const response = await createOrganisationForSchema(postData).unwrap();
        if (response?.success) {
          const data = {
            name: formattedArea,
            key: formattedArea,
          };
          const res = await createOrganization(data).unwrap();
          const newUser = {
            ...user,
            organization: formattedArea,
          };
          localStorage.setItem("user", JSON.stringify(newUser));
          toast.success("Organization created successfully");
          const response = await getOrganizationDetails(formattedArea).unwrap();
          // localStorage.setItem("defaultUnits", response?.token);
          navigate("/", { replace: true });
        }
      } catch (error) {
        if (
          error?.data?.message ===
          "Organisation already created with this Organisation Name."
        ) {
          setIsConfirmationModalOpen(true);
        } else if (error?.data?.message === "User Already exist.") {
          toast.error(error?.data?.message, {
            autoClose: 1000,
            hideProgressBar: true,
          });
        } else {
          toast.error(error?.data?.[0], {
            autoClose: 1000,
            hideProgressBar: true,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching pin code details:", error);
    }
  };

  const handleOk = async () => {
    const postData = {
      organisationName: formattedAddress,
      userEmail: user?.email.replace("+","%2B"),
      linkWithOrganisation: true,
    };
    const response = await createOrganisationForSchema(postData).unwrap();
    setIsConfirmationModalOpen(false);
    if (response?.success) {
      try {
        const data = {
          name: formattedAddress,
          key: formattedAddress,
        };
        const res = await createOrganization(data).unwrap();
        const newUser = {
          ...user,
          organization: formattedAddress,
        };
        localStorage.setItem("user", JSON.stringify(newUser));
        toast.success("Organization linked successfully");
        navigate("/", { replace: true });
      } catch (error) {
        toast.error(error?.data?.[0], {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    }
  };

  useEffect(() => {
    if (user?.organization) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <AuthDesignWrapper
      body={
        <>
          <Typography
            fontWeight={"600"}
            sx={{ color: blackShade1.main }}
            component="h3"
            variant="h5"
          >
            Create your organization
          </Typography>
          <Box
            component="form"
            width="100%"
            noValidate
            onSubmit={handleFormSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <div className="formFields">
              <Typography
                fontWeight={"700"}
                sx={{ color: blackShade1.light }}
                component="body1"
                variant="body1"
              >
                Country
              </Typography>
              <Controller
                name="country"
                control={control}
                rules={{ required: "Please select a country" }}
                render={({ field }) => (
                  <Select
                    id="country"
                    size="small"
                    fullWidth
                    {...field}
                    displayEmpty
                    error={!!errors.country}
                    sx={{
                      "& .MuiSelect-select:focus": {
                        backgroundColor: "inherit",
                      },
                      "& .MuiSelect-select": {
                        color: field.value
                          ? "inherit"
                          : "rgba(60, 60, 60, 0.54)",
                      },
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{ color: "rgba(60, 60, 60, 0.54)" }}
                    >
                      Select Country
                    </MenuItem>
                    <MenuItem value="US">United States of America</MenuItem>
                    <MenuItem value="AU">Australia</MenuItem>
                  </Select>
                )}
              />
            </div>
            <Typography color="red" marginLeft="13px" fontSize="12.5px">
              {errors.country ? errors.country.message : ""}
            </Typography>
            <div className="formFields">
              <Typography
                fontWeight={"700"}
                sx={{ color: blackShade1.light }}
                component="body1"
                variant="body1"
              >
                Pincode
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="pincode"
                name="pincode"
                placeholder="Enter the Pincode / zip code your property is in "
                {...register("pincode", {
                  required: "Pincode is required",
                })}
                error={!!errors.pincode}
                helperText={errors.pincode ? errors.pincode.message : ""}
              />
            </div>
            <Button
              type="submit"
              fullWidth
              disabled={isLoading || isSchemaLoading}
              variant="contained"
              sx={{ mt: 4, mb: 2, boxShadow: "none" }}
            >
              {isLoading || isSchemaLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Create Organization"
              )}
            </Button>
          </Box>
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            setIsOpen={setIsConfirmationModalOpen}
            title="Merge Organization"
            body="Organisation already exist.Do you want to merge with existing organization?"
            handleOk={handleOk}
          />
        </>
      }
    ></AuthDesignWrapper>
  );
};

export default NewUser;
