import { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

const ColorPickerButton = ({ value, setValue, disabled }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleClick = () => {
    if(disabled) return;
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setValue(color.hex);
  };
  const styles = reactCSS({
    default: {
      container: {
        display: "inline-flex",
        position: "relative"
      },
      color: {
        width: "2rem",
        height: "2rem",
        borderRadius: "0.15rem",
        background: value,
      },
      swatch: {
        padding: "0.25rem",
        background: "#fff",
        borderRadius: "0.15rem",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: disabled ? "default" : "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: 2,
        right: 0,
        top: "3rem",
      },
      cover: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  });

  return (
    <div style={styles.container}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker && (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={value} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPickerButton;
