import React, { useState, useEffect, useMemo, forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { getCustomColor } from "../../utils/theme";
import { generateRandomColor } from "../../utils/colorFunctions";
import "./AddServiceLayerModal.css";
import AddServiceStep from "./AddServiceStep";
import AddEquipmentStep from "./AddEquipmentStep";
import DetailSectionStep from "./DetailSectionStep";
import { IoMdClose } from "react-icons/io";
import { fetchS3Data } from "../LocationGroupModal/LocationGroupModal";
import Pbf from "pbf";
import geobuf from "geobuf";
import { useGetPlannerLayerQuery } from "../../services/OpenApi";
import { setPlanningPopupSelectedFilteredGeoJson } from "../../slices/planningDashboardSlice";
import { useDispatch } from "react-redux";

const AddServiceLayerModal = forwardRef(({
  isOpen,
  handleClose,
  editServiceData,
  method = "ADD",
  setEditServiceData,
}, tempRef) => {
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const v2PlannerId = searchParams.get("v2PlannerId");
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch()
  const {
    data: planner,
  } = useGetPlannerLayerQuery({ id: v2PlannerId, orgName: user?.organization });

  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedCompleteBefore, setSelectedCompleteBefore] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [isAddNewService, setIsAddNewService] = useState(null);
  const [newServiceName, setNewServiceName] = useState(null);
  const [selectedParents, setSelectedParents] = useState({
    isReady: false,
    data: {}
  });
  const [color, setColor] = useState(generateRandomColor);

  const steps = ["Add a service", "Assign Equipment", "Detail Selection"];

  const handleNext = (data) => {
    if (data?.from === "service") {
      setSelectedServiceId(data.coreServiceId);
      setSelectedCompleteBefore(data.completeBefore);
      setSelectedLayers(data?.selectedLayers);
      setIsAddNewService(data?.isAddNewService);
      setNewServiceName(data?.newServiceName);
    } else if (data?.from === "equipment") {
      setSelectedEquipment(data?.equipment);
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCancel = () => {
    handleClose();
    dispatch(setPlanningPopupSelectedFilteredGeoJson(null));
    setActiveStep(0);
    setSelectedLayers([]);
    setSelectedServiceId(null);
    setSelectedCompleteBefore(null);
    setSelectedEquipment(null);
    setIsAddNewService(null);
    setNewServiceName(null);
    setSelectedParents({
      isReady: false,
      data: {}
    })
    if(method === "EDIT")
      setEditServiceData(null)
  };

  const handleLayerSelection = (layers) => {
    setSelectedLayers(layers);
  };

  const coreServiceId = useMemo(()=>editServiceData?.coreServiceId, [editServiceData]) 
  useEffect(() => {
    if (editServiceData) {
      setSelectedServiceId(editServiceData?.coreServiceId);
      setSelectedCompleteBefore(
        editServiceData?.completeBeforeServiceId
          ? editServiceData?.completeBeforeServiceId
          : null
      );
      const services = editServiceData?.layers?.flatMap(
        (layer) => layer?.v2CoreLayerName
      );
      setSelectedLayers(services ? services : []);
      setSelectedEquipment(editServiceData?.equipments || []);
      setColor(editServiceData?.color || generateRandomColor());
      // set Selected Parents here
      const formattedSelection = editServiceData?.layers?.reduce((acc, curr) => {
        acc[curr.v2CoreLayerName] = [];
        let temp = acc[curr.v2CoreLayerName];
        curr.polygons.forEach((polygon) => {
          let [parentId] = polygon.pathId.split("-");
          let parentIdIndex = temp.findIndex(x => x.parentId === parentId)
          // console.log("test polygon", [curr.v2CoreLayerName], polygon, parentIdIndex)
          if(parentIdIndex > -1) {
            temp[parentIdIndex] = {
              ...temp[parentIdIndex],
              polygons: [...temp[parentIdIndex].polygons, polygon]
            }
          } else {
            temp.push({
              parentId,
              polygons: [polygon]
            })
          }
        })
        return acc
      }, {})
      setSelectedParents({
        isReady: true,
        data: formattedSelection
      })
    } else {
      setColor(generateRandomColor);
    }
  }, [coreServiceId, editServiceData, isOpen]);

  const conditionalRenderFlag = useMemo(() => {
    if(method === "ADD") return true
    if(selectedParents.isReady) 
      return true
    return false
  }, [method, selectedParents.isReady])

  return (
    <Dialog
      container={tempRef.current}
      open={isOpen}
      hideBackdrop // Disable the backdrop color/image
      disableEnforceFocus // Let the user focus on elements outside the dialog
      style={{ position: "initial" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
      onClose={handleCancel}
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, calc(-50% - 3.25rem))",
          zIndex: 100,
          margin: 0,
          width: "35vw",
          minWidth: "28rem",
          paddingBottom: "20px",
          height: "32rem",
        },
      }}
    >
      <DialogTitle align="left" sx={{ textTransform: "capitalize" }}>
        {method.toLowerCase()} Service Layer
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: "0",
          paddingBottom: "0",
          padding: "0 10px",
          "& .MuiDialogContent": {
            padding: "0",
          },
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            backgroundColor: "rgba(192, 192, 192, 0.2)",
            width: "0.25rem"
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "rgba(87, 87, 87, 0.2)"
          }
        }}
      >
        <Box className="stepsContainer">
          {steps.map((label, index) => (
            <Box
              className="stepContainer"
              key={index + activeStep}
              sx={{ background: activeStep === index ? "#D0FFA7" : "none" }}
            >
              <Box className="stepNumberContainer">{index + 1}</Box>
              <Typography
                fontWeight="600"
                sx={{
                  color:
                    activeStep === index
                      ? greenShade1.shade1
                      : blackShade1.shade2,
                  fontSize: "11px",
                }}
                component="span"
              >
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
        {activeStep === 0 && (
          <AddServiceStep
            readOnly={method === "VIEW"}
            activeStep={activeStep}
            handleNext={handleNext}
            handleLayerSelection={handleLayerSelection}
            selectedServiceId={selectedServiceId}
            savedLayers={selectedLayers}
            selectedCompleteBefore={selectedCompleteBefore}
            selectedIsAddNewService={isAddNewService}
            selectedNewServiceName={newServiceName}
            editServiceData={editServiceData}
            color={color}
            setColor={setColor}
          />
        )}

        {activeStep === 1 && (
          <AddEquipmentStep
            readOnly={method === "VIEW"}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            selectedEquipment={selectedEquipment}
            orgName={user?.organization}
          />
        )}

        {activeStep === 2 && conditionalRenderFlag &&(
          <DetailSectionStep
            readOnly={method === "VIEW"}
            selectedLayers={selectedLayers}
            planner={planner?.data}
            coreServiceId={selectedServiceId}
            selectedEquipment={selectedEquipment}
            selectedCompleteBefore={selectedCompleteBefore}
            selectedIsAddNewService={isAddNewService}
            selectedNewServiceName={newServiceName}
            handleBack={handleBack}
            handleClose={handleCancel}
            method={method}
            selectedParents={selectedParents.data}
            setSelectedParents={(newData)=>{
              setSelectedParents(prev=>({...prev, data: newData}))
            }}
            selectedParentsFlag={selectedParents.isReady}
            editServiceData={editServiceData}
            color={color}
          />
        )}
      </DialogContent>
    </Dialog>
  );
});

export default AddServiceLayerModal;
