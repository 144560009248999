import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineClose } from "react-icons/ai";
import { Drawer, Stack, Typography } from "@mui/material";

export const ZoomControls = ({ handleZoom }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        padding: 0,
        gap: 0,
        background: "#fff",
        borderRadius: "0.25rem",
        border: "solid 1px #dedede",
      }}
    >
      <div
        style={{
          padding: "0.85rem 0.5rem",
          cursor: "pointer",
        }}
        onClick={() => handleZoom(true)}
      >
        <AiOutlinePlus />
      </div>
      <div
        style={{
          padding: "0.85rem 0.5rem",
          cursor: "pointer",
        }}
        onClick={() => handleZoom(false)}
      >
        <AiOutlineMinus />
      </div>
    </div>
  );
};

export const MapTypeControls = ({ changeMapType, mapType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const mapTypeList = ["satellite", "roadmap", "terrain", "hybrid"];
  const handleMapTypeChange = (mapType) => {
    changeMapType(mapType);
    setIsExpanded(false);
  };
  return (
    <div>
      <button
        onClick={() => setIsExpanded(true)}
        style={{
          background: "#fff",
          borderRadius: "0.3rem",
          border: "solid 1px #dedede",
          padding: "0.15rem",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          cursor: "pointer"
        }}
      >
        <img
          src={`images/Icons/maptype_${mapType}.png`}
          alt="Map"
          style={{
            height: "2.25rem",
            width: "2.25rem",
          }}
        />
      </button>
      <Drawer
        anchor="bottom"
        open={isExpanded}
        hideBackdrop
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "0 0 10px 10px",
            maxHeight: "8.5rem",
            position: "fixed",
            bottom: 0,
            right: 10,
            left: "auto",
            zIndex: "1",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p="0.5rem 0 0.5rem 1rem"
        >
          <Typography variant="h6" component="h6" fontWeight={600}>
            Layers
          </Typography>
          <span
            onClick={() => setIsExpanded(false)}
            style={{
              cursor: "pointer",
              padding: "0.25rem 0.5rem",
            }}
          >
            <AiOutlineClose size={18} />
          </span>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          sx={{
            p: "0rem 1rem 1rem 1rem",
          }}
        >
          {mapTypeList.map((item, index) => {
            return (
              <Stack
                key={index}
                onClick={() => handleMapTypeChange(item)}
                alignItems="center"
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={`images/Icons/maptype_${item}.png`}
                  alt="Map"
                  style={{
                    border: `2px solid ${item === mapType ? "#62D100" : "#fff"}`,
                    borderRadius: "0.45rem",
                    height: "3rem",
                    width: "3rem",
                  }}
                />
                <Typography
                  variant="caption"
                  component="span"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Drawer>
    </div>
  );
};
