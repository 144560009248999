import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import UploadModal from "../../../Modals/UploadModal/uploadModal";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import { useDeleteOrderMutation } from "../../../services/OpenApi";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import ColumnHeader from "./ColumnHeader";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { GoUpload } from "react-icons/go";
import AddRunPlannerFileNameModal from "../../../Modals/AddRunPlannerFileNameModal/AddRunPlannerFileNameModal";
import PlansColumnList from "./PlansColumnList";
import { update } from "lodash";

const PlansColumn = ({
  selectedProperty,
  selectedLegacyPropertyPlans,
  selectedPropertyPlanner,
  setSelectedPropertyPlanner,
  setSelectedProperty,
  isLoadingLegacyPlans,
  setRefreshData
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(null);
  const [deleteOrder] = useDeleteOrderMutation();
  const handleOk = async () => {
    try {
      await deleteOrder({
        orderId: isConfirmationModalOpen,
        orgName: user?.organization,
      });
      setIsConfirmationModalOpen(false);
      setSelectedProperty({});
    } catch (err) {
      setIsConfirmationModalOpen(false);
    }
  };

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isAddPlanModalOpen, setIsAddPlanModalOpen] = useState(false);

  return (
    <div
      style={{
        borderLeft: "1px solid #bdc0be",
        borderRight: "1px solid #bdc0be",
        width: "20%",
      }}
    >
      <ColumnHeader>Plans</ColumnHeader>
      <div
        className="modern-scroll"
        style={{
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "0.5rem",
          // 100vh - header height - (column header height + padding)
          height: "calc(100vh - 8vh - 12.85rem)",
          overflowY: "auto",
        }}
      >
        {["PARCEL", "PARKING", "SITE"].includes(selectedProperty.status) && (
          <Stack gap={1}>
            <Link
              to={`/parcel/${selectedProperty.id}`}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <Button
                variant="contained"
                color="mintGreen"
                sx={{
                  width: "100%",
                  color: "#1D2220",
                }}
              >
                Continue Survey
                <IoIosArrowRoundForward size={24} />
              </Button>
            </Link>
            <Button
              variant="outlined"
              color="maroon"
              onClick={() => setIsConfirmationModalOpen(selectedProperty.id)}
            >
              Delete Draft
            </Button>
          </Stack>
        )}
        {selectedProperty.status === "SUBMITTED" && (
          <Stack gap={1}>
            <SecondaryButton
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <Link
                to={`summary/${selectedProperty.id}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <IoEyeOutline />
                View
              </Link>
            </SecondaryButton>
            <SecondaryButton
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
              onClick={() => setIsUploadModalOpen(true)}
            >
              <GoUpload />
              Upload
            </SecondaryButton>
            <Button
              variant="outlined"
              color="maroon"
              onClick={() => setIsConfirmationModalOpen(selectedProperty.id)}
            >
              Delete Draft
            </Button>
          </Stack>
        )}
        {/* Plans List */}
        <div
          className="modern-scroll"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            flex: 1,
          }}
        >
          {selectedProperty.id &&
            selectedProperty.status !== "SUBMITTED" &&
            selectedProperty.status !== "PREPARING_PLAN" && (
              <div
                className="modern-scroll"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  flex: 1,
                }}
              >
                <PlansColumnList
                  selectedProperty={selectedProperty}
                  selectedLegacyPropertyPlans={selectedLegacyPropertyPlans}
                  setSelectedPropertyPlanner={setSelectedPropertyPlanner}
                  selectedPropertyPlanner={selectedPropertyPlanner}
                  isLoadingLegacyPlans={isLoadingLegacyPlans}
                />
              </div>
            )}

          {selectedProperty.id &&
            selectedProperty.status !== "SUBMITTED" &&
            selectedProperty.status !== "PREPARING_PLAN" && (
              <SecondaryButton onClick={() => setIsAddPlanModalOpen(true)}>
                + New Plan
              </SecondaryButton>
            )}
        </div>
      </div>
      <UploadModal
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        uploadData={selectedProperty}
        onSuccess={() => {
          setRefreshData(true)
          setSelectedProperty((prev) => ({
            ...prev,
            status: "PREPARING_PLAN",

          }));
        }}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete this property?"
        handleOk={handleOk}
      />
      <AddRunPlannerFileNameModal
        isOpen={isAddPlanModalOpen}
        onClose={() => setIsAddPlanModalOpen(false)}
        fileData={{
          v2PlannerId: selectedProperty.v2PlannerId,
          site: "SiteName",
        }}
      />
    </div>
  );
};

export default PlansColumn;
