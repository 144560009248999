import React, { useEffect, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../utils/common";
import useViewportWidth from "../../Hooks/useViewportWidth";
import { DeckGlOverlay } from "../../utils/deckgl-overlay";

function getDeckGlLayers(data) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "geojsonBase",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: 1,
      getFillColor: (f) => {
        return getServiceColorRgb(f?.properties?.layerType);
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.layerType),
      lineWidthMinPixels: 2,
      parameters: {
        depthTest: false,
      },
      pickable: true,
    }),
  ];
}

const HoveredGeoJsonLayers = ({ geoJson }) => {
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(geoJson)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default HoveredGeoJsonLayers;
