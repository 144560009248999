import { Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { PiFileTextFill } from "react-icons/pi";
import { Link } from "react-router-dom";

const PlansColumnList = ({
  selectedProperty,
  selectedLegacyPropertyPlans,
  setSelectedPropertyPlanner,
  selectedPropertyPlanner,
  isLoadingLegacyPlans,
}) => {
  if (!selectedProperty) return null;
  if (selectedProperty.isNewParcel) {
    if (selectedProperty.v2RunPlannerDtoList.length === 0) {
      return (
        <Typography variant="caption" component="p">
          No Plans Available.
        </Typography>
      );
    }
    return selectedProperty.v2RunPlannerDtoList.map((runPlanner) => (
      <Stack
        key={runPlanner.v2runPlannerId}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          setSelectedPropertyPlanner(runPlanner);
        }}
        className={`property-runplanner ${selectedPropertyPlanner.v2runPlannerId === runPlanner.v2runPlannerId ? "selected" : ""}`}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <PiFileTextFill size={18} />
          <Typography variant="body2" component="span">
            {runPlanner.runPlannerName}
          </Typography>
        </Stack>
        {selectedPropertyPlanner.v2runPlannerId ===
          runPlanner.v2runPlannerId && <MdOutlineKeyboardArrowRight />}
      </Stack>
    ));
  } else {
    if (isLoadingLegacyPlans) {
      return (
        <Skeleton variant="text" width="100%" height={50} />
      );
    }
    if (selectedLegacyPropertyPlans.length === 0) {
      return (
        <Typography
          variant="caption"
          component="p"
          sx={{
            padding: "0.5rem 0.5rem",
          }}
        >
          No Plans Available.
        </Typography>
      );
    }
    return selectedLegacyPropertyPlans.map((runPlanner) => (
      <Link
        to={`map?orderId=${selectedProperty.id}&runPlannerId=${runPlanner.runPlannerId}`}
        key={runPlanner.runPlannerId}
        style={{
            textDecoration: "none",
            color: "inherit",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="property-runplanner"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <PiFileTextFill size={18} />
            <Typography variant="body2" component="span">
              {runPlanner.runPlannerName}
            </Typography>
          </Stack>
        </Stack>
      </Link>
    ));
  }
};

export default PlansColumnList;
