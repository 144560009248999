import { APIProvider } from "@vis.gl/react-google-maps";
import React, { useState } from "react";
import UploadGeoJsonMap from "./UploadGeoJsonMap";
import { Checkbox, Typography } from "@mui/material";
import GeoJSONUploadButton from "../MapScreen/GeoJSONUploadButton";
import { ShuffleJson } from "../../utils/common";
import GeoBufJSONUploadButton from "../MapScreen/GeoBufJSONUploadButton";


const UploadGeoJson = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [connectingLinesData, setConnectingLinesData] = useState(null);
  const [crewMembers, setCrewMembers] = useState(null);
  const [layerTypes, setLayerTypes] = useState(null);
  const [isBuf, setIsBuf] = useState(null);
  const handleGeoFileUpload = (file) => {
    ShuffleJson(file);
    setGeoJsonData(file);
    const layerTypes = {};

    // Loop through each feature and check its layerType
    file.features.forEach((feature) => {
      const layerType = feature.properties.layerType;

      // If the layerType is not already in the object, add it and set to true
      if (layerType && !layerTypes[layerType]) {
        layerTypes[layerType] = true;
      }
    });
    setLayerTypes(layerTypes);
    setIsBuf(false);
  };

  const handleGeoFileUpload1 = (file) => {
    setGeoJsonData(file);

    setIsBuf(true);
    setLayerTypes(null);
  };


  const handleConnectingLines = (file)=> {
    setConnectingLinesData(file)
    const crewMembers = {}

    Object.keys(file).forEach((crewMember) => {
      if(!crewMembers[crewMember]) {
        crewMembers[crewMember] = false 
      }
    })
    setCrewMembers(crewMembers)
  }

  const handleServicesCheckboxChange = (key) =>
    setLayerTypes((prevLayerTypes) => ({
      ...prevLayerTypes,
      [key]: !prevLayerTypes[key],
    }));
  const handleCrewMemberCheckboxChange = (key) =>
  setCrewMembers((prevLayerTypes) => ({
      ...prevLayerTypes,
      [key]: !prevLayerTypes[key],
    }));
  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <UploadGeoJsonMap geoJsonData={geoJsonData} layerTypes={layerTypes} connectingLinesData={connectingLinesData} crewMembers={crewMembers} bufValue={isBuf} />
        </APIProvider>
      </div>
      <button className="geoBufFileUpload1">
          <GeoBufJSONUploadButton onFileUpload={handleGeoFileUpload1} />
        </button>
      {layerTypes ? (
        <div className="mapControlsForLayers">
          <GeoJSONUploadButton onFileUpload={handleGeoFileUpload} />
          {Object.keys(layerTypes)?.map((key) => {
            return (
              <div className="layerCheckboxContainer">
                <Checkbox
                  size="small"
                  checked={layerTypes[key]}
                  onChange={() => handleServicesCheckboxChange(key)}
                />
                <Typography component="h3" fontWeight="600" variant="body2">
                  {key}
                </Typography>
              </div>
            );
          })}
        </div>
      ) : (
        <button className="geoFileUpload">
          <GeoJSONUploadButton onFileUpload={handleGeoFileUpload} />
        </button>
      )}
      {crewMembers ? (
        <div className="mapControlsForCrew">
          <GeoJSONUploadButton onFileUpload={handleConnectingLines} buttonText="Upload Again"/>
          {Object.keys(crewMembers)?.map((key) => {
            return (
              <div className="layerCheckboxContainer">
                <Checkbox
                  size="small"
                  checked={crewMembers[key]}
                  onChange={() => handleCrewMemberCheckboxChange(key)}
                />
                <Typography component="h3" fontWeight="600" variant="body2">
                  {key}
                </Typography>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="connectingLineUpload">
          <GeoJSONUploadButton onFileUpload={handleConnectingLines} buttonText="Upload Connecting Line"/>
        </div>
      )}
        
    </div>
  );
};

export default UploadGeoJson;
