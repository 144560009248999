import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useUploadKMLFileMutation } from "../../services/OpenApi";
const UploadModal = ({
  isOpen,
  setIsOpen,
  uploadData,
  editData,
  site,
  onSuccess = () => {},
}) => {
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadKMLFile, { isLoading }] = useUploadKMLFileMutation();
  const user = useSelector((state) => state.global.user);
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.name.endsWith(".zip")) {
      setFile(droppedFile);
    } else {
      alert("Only .zip files are allowed.");
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".zip")) {
      setFile(selectedFile);
    } else {
      alert("Only .zip files are allowed.");
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    try {
      const response = await uploadKMLFile({
        file,
        parcelId: uploadData?.id,
        organisationName: user?.organization,
        propertyName: uploadData?.site,
      }).unwrap();
      if (response) {
        handleCancel();
        onSuccess()
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
    onSuccess();
    handleCancel();
  };

  const handleCancel = () => {
    setFile(null);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontWeight: 700, fontSize: "20px" }}>
        Upload File
      </DialogTitle>
      <Divider />
      <Box
        p={2}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          border: isDragging ? "2px dashed #62D100" : "1px dashed #ccc",
          borderRadius: "4px",
          textAlign: "center",
          padding: "20px",
          cursor: "pointer",
        }}
        onClick={() => document.getElementById("fileInput").click()}
      >
        {file ? (
          <Typography>{file.name}</Typography>
        ) : (
          <Typography>
            Drag & Drop a file or click below to upload
            <br />
            <span style={{ color: "#62D100", textDecoration: "underline" }}>
              Browse Files
            </span>
          </Typography>
        )}
      </Box>
      <input
        type="file"
        id="fileInput"
        accept=".zip"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Box mt={1} mb={2} mr={1} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          disabled={!file || isLoading}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Box>
    </Dialog>
  );
};

export default UploadModal;
