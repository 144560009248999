import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";

function getDeckGlLayers(
  layerData,
  handleHoveredPolygon,
  handleClickedPolygon,
  isCrewMemberSelected,
  lookUpData,
  shouldLowerOpacity,
  pathIdToServiceIdMap,
) {
  if (!layerData) return [];
  
  return [
    new GeoJsonLayer({
      id: "geojson",
      data: layerData,
      stroked: true,
      filled: true,
      getLineWidth: isCrewMemberSelected ? 0 : 0.2,
      opacity: shouldLowerOpacity ? 0.005: (isCrewMemberSelected ? 0.005 : 1),
      getFillColor: (f) => {
        const hexColor = lookUpData?.services[pathIdToServiceIdMap[f.properties.pathId]]?.color;
        return hexColor ? hexToRgb(hexColor) : hexToRgb(f?.properties?.fill);
      },
      getLineColor: (f) => {
        const hexColor = lookUpData?.services[pathIdToServiceIdMap[f.properties.pathId]]?.color;
        return hexColor ? hexToRgb(hexColor) : hexToRgb(f?.properties?.fill);
      },
      lineWidthMinPixels: isCrewMemberSelected ? 0 : 2,
      onHover: (info) => handleHoveredPolygon(info),
      onClick: (info) => handleClickedPolygon(info),
      pickable: true,
    }),
  ];
}

const PlannerBaseLayer = ({
  coveredFilteredGeoJson,
  handleHoveredPolygon,
  handleClickedPolygon,
  shouldLowerOpacity,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isCrewMemberSelected, setIsCrewMemberSelected] = useState(true);

  const filteredGeoJson = useSelector(
    (state) => state.planningDashboard.planningMapGeoJsonFilteredData,
  );
  const planningSelectedCrewMembersId = useSelector(
    (state) => state.planningDashboard.planningSelectedCrewMembersId,
  );
  const lookUpData = useSelector(
    (state) => state.planningDashboard.plannedLookUpData,
  );

  useEffect(() => {
    if (planningSelectedCrewMembersId) {
      let isTrue = false;
      Object.keys(planningSelectedCrewMembersId).forEach((key) => {
        if (planningSelectedCrewMembersId[key]) {
          isTrue = true;
        }
      });
      setIsCrewMemberSelected(isTrue);
    }
  }, [planningSelectedCrewMembersId]);

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const pathIdToServiceIdMap = useMemo(() => {
    const temp = {};
    Object.keys(lookUpData?.polygons || {}).forEach((polygonId) => {
      const serviceId = lookUpData?.polygons[polygonId]?.serviceId;
      if (serviceId) {
        temp[polygonId.split("-").slice(1,3).join('-')] = serviceId;
      }
    });
    return temp;
  }, [lookUpData]);
  if (!Object.keys(pathIdToServiceIdMap)) return null;

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        filteredGeoJson,
        handleHoveredPolygon,
        handleClickedPolygon,
        isCrewMemberSelected,
        lookUpData,
        shouldLowerOpacity,
        pathIdToServiceIdMap,
      )}
      pickable={true}
      isVisible={isVisible}
    />
  );
};

export default PlannerBaseLayer;
