import React from "react";
import { useSelector } from "react-redux";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CrewMemberTimelinePlanner from "./CrewMemberTimelinePlanner";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";

const PlannerTimelineDrawer = ({
  isPlannerDrawerOpen,
  isTimelineDrawerOpen,
  setIsTimelineDrawerOpen,
  setHoveredPolygon,
  drawerRef,
  tabValue,
}) => {
  const lookUpServices = useSelector(
    (state) => state.planningDashboard.plannedLookUpData.services,
  );
  const width = useViewportWidth();
  return (
    <Drawer
      open={isTimelineDrawerOpen}
      direction="bottom"
      className="insidedrawer"
      lockBackgroundScroll={false}
      enableOverlay={false}
      style={{
        height: "auto",
        borderRadius: "10px",
        bottom: "2vh",
        right: "2vw",
        padding: "12px",
        left: isPlannerDrawerOpen && width > 768 ? "40vw" : "2vw",
        width: isPlannerDrawerOpen && width > 768 ? "58vw" : "94vw",
        border: "solid 1px #D0FFA7"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          width: "100%"
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          fontWeight={600}
          color="#616965"
        >
          Gantt Chart
        </Typography>
        <IconButton
          onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
        >
          <MdClose />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        gap={3}
        flexWrap="wrap"
        marginBottom={1}
      >
        {lookUpServices && Object.keys(lookUpServices).map(serviceId=>(
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}>
            <span
              style={{
                borderRadius: "25%",
                padding: "0.4rem",
                backgroundColor: lookUpServices[serviceId].color,
              }}
            ></span>
            <Typography
              variant="body2"
              component="span"
            >
              {lookUpServices[serviceId].serviceName}
            </Typography>
          </Box>
        ))}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}>
          <span
            style={{
              borderRadius: "25%",
              padding: "0.4rem",
              backgroundColor: "#7a7a7a",
            }}
          ></span>
          <Typography
            variant="body2"
            component="span"
          >
            Back to Truck
          </Typography>
        </Box>
      </Stack>
      <div ref={drawerRef}>
        <div className="graphContainer">
          <div>
            <CrewMemberTimelinePlanner
              onBarHover={(polygon) => setHoveredPolygon(polygon)}
              onBarLeave={() => setHoveredPolygon(null)}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PlannerTimelineDrawer;
