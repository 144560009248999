import React, { useState, forwardRef } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import { toast } from "react-toastify";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import {
  useDeleteRunPlannerServiceMutation,
  useLazyGetRunPlannerServiceQuery,
} from "../../../services/OpenApi";
import AddServiceLayerModal from "../../../Modals/AddServiceLayerModal/AddServiceLayerModal";
import { getCustomColor } from "../../../utils/theme";
import { useSelector } from "react-redux";

const ServicesTable = forwardRef(
  (
    {
      servicesGetData,
      isDataLoading,
      isEditServiceModalOpen,
      setIsEditServiceModalOpen,
    },
    tempRef
  ) => {
    const greenShade1 = getCustomColor("greenShade1");
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState(false);
    const [editServiceData, setEditServiceData] = useState(null);
    const [deleteServiceId, setDeleteServiceId] = useState(null);
    const [editingServiceId, setEditingServiceId] = useState(null); // ID of the service being edited
    const [getRunPlannerService, { isFetching: isEditLoading }] =
      useLazyGetRunPlannerServiceQuery();
    const [deleteRunPlannerService, { isLoading }] =
      useDeleteRunPlannerServiceMutation();
    const user = JSON.parse(localStorage.getItem("user"));
    const planningInputData = useSelector(
      (state) => state.planningDashboard.planningInputData
    );
    const handleDeleteServiceClick = (id) => {
      setIsConfirmationModalOpen(true);
      setDeleteServiceId(id);
    };

    const handleOk = async () => {
      try {
        const response = await deleteRunPlannerService({
          id: deleteServiceId,
          orgName: user?.organization,
        });
        if (response) {
          setIsConfirmationModalOpen(false);
          setDeleteServiceId(null);
          toast.success("Service deleted successfully", {
            autoClose: 1000,
            hideProgressBar: true,
          });
        }
      } catch (err) {
        setIsConfirmationModalOpen(false);
      }
    };

    const handleEditClick = async (id) => {
      setEditingServiceId(id); // Set the ID of the service being edited
      try {
        const response = await getRunPlannerService({
          id: id,
          orgName: user?.organization,
        });
        if (response) {
          setEditServiceData(response?.data?.data);
          setIsEditServiceModalOpen(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setEditingServiceId(null); // Reset the editing ID after loading is complete
      }
    };

    return (
      <>
        <TableContainer
          className="serviceTableContainer"
          component={Paper}
          sx={{ boxShadow: "none", maxHeight: "24vh" }}
        >
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead sx={{ backgroundColor: "#8FE445", height: "10px" }}>
              <TableRow sx={{ height: `10px`, padding: "0px 6px" }}>
                <StyledTableCell
                  sx={{
                    maxWidth: 80,
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                  style={{ paddingLeft: "16px" }}
                >
                  Service
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                    padding: "0px 6px",
                  }}
                  align="center"
                >
                  Layers
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#8FE445",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "16px",
                    padding: "0px 6px",
                  }}
                  align="center"
                >
                  Dimension
                </StyledTableCell>
                {planningInputData?.currentStatus === "NOT_SOLVING" && (
                  <StyledTableCell
                    sx={{
                      maxWidth: 80,
                      whiteSpace: "nowrap",
                      backgroundColor: "#8FE445",
                      color: "white",
                      fontWeight: 600,
                      fontSize: "16px",
                      padding: "0px 6px",
                    }}
                    align="right"
                  >
                    <Box sx={{ paddingRight: "10px" }}>Actions</Box>
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading ? (
                // Render skeletons if data is loading
                Array.from({ length: 4 }).map((_, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          sx={{ bgcolor: greenShade1?.skeleton }}
                          variant="circular"
                          width={18}
                          height={18}
                        />
                        <Skeleton
                          sx={{ bgcolor: greenShade1?.skeleton }}
                          variant="text"
                          width="100%"
                          height={25}
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton
                        sx={{ bgcolor: greenShade1?.skeleton }}
                        variant="text"
                        width="100%"
                        height={25}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton
                        sx={{ bgcolor: greenShade1?.skeleton }}
                        variant="text"
                        width="100%"
                        height={25}
                      />
                    </StyledTableCell>
                    {planningInputData?.currentStatus === "NOT_SOLVING" && (
                    <StyledTableCell align="center">
                      <Skeleton
                        sx={{ bgcolor: greenShade1?.skeleton }}
                        variant="text"
                        width="100%"
                        height={25}
                      />
                    </StyledTableCell>)}
                  </TableRow>
                ))
              ) : servicesGetData.length === 0 ? (
                <StyledTableCell colSpan={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: "13px",
                      color: "rgb(95, 97, 99)",
                    }}
                  >
                    No service added yet
                  </Typography>
                </StyledTableCell>
              ) : (
                servicesGetData.map((service) => (
                  <TableRow key={service.runPlannerServiceId}>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <span style={{
                          backgroundColor: service.color,
                          height: "0.8rem",
                          width: "0.8rem",
                          borderRadius: "50%",
                          display: "inline-block",
                        }} />
                        <span style={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                          {service.coreServiceName}
                        </span>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {service.coreLayers.reduce(
                        (prev, curr) =>
                          prev + (prev ? "+" : "") + curr.layerName,
                        ""
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      {service?.dimension ?? "calculating"}
                    </StyledTableCell>
                    {planningInputData?.currentStatus === "NOT_SOLVING" && (
                      <StyledTableCell
                        sx={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        align="right"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            gap: "10px",
                          }}
                        >
                          {isEditLoading &&
                          editingServiceId === service.runPlannerServiceId ? (
                            <CircularProgress size={14} thickness={5} />
                          ) : (
                            <button
                              onClick={() =>
                                handleEditClick(service?.runPlannerServiceId)
                              }
                              style={{
                                border: "none",
                                background: "white",
                                padding: 0,
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src="/images/Icons/PencilSimple.png"
                                alt="Edit"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </button>
                          )}
                          <button
                            onClick={() =>
                              handleDeleteServiceClick(
                                service?.runPlannerServiceId
                              )
                            }
                            style={{
                              border: "none",
                              background: "white",
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/Icons/Trash.png"
                              alt="Delete"
                              style={{ width: "17px", height: "17px" }}
                            />
                          </button>
                        </Box>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          body="Do you want to delete this service?"
          handleOk={handleOk}
          isLoading={isLoading}
        />
        <AddServiceLayerModal
          method="EDIT"
          isOpen={isEditServiceModalOpen}
          handleClose={() => setIsEditServiceModalOpen(false)}
          editServiceData={editServiceData}
          setEditServiceData={setEditServiceData}
          ref={tempRef}
        />
      </>
    );
  }
);

export default ServicesTable;
